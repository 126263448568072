import { translationsEn } from "./en";
import { translationsAr } from "./ar";
import { translationsZh } from "./zh";
import { translationsPt } from "./pt";
import { translationsRu } from "./ru";

export const resources = {
    en: { translation: translationsEn, title: "EN" },
    ar: { translation: translationsAr, title: "عربي" },
    zh: { translation: translationsZh, title: "中文" },
    pt: { translation: translationsPt, title: "PT" },
    ru: { translation: translationsRu, title: "RU" },
};
