export const FlagIcon = `<svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_517_1176)">
<path d="M6.64 1.17647L6.4 0H1V10H2.2V5.88235H5.56L5.8 7.05882H10V1.17647H6.64Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_517_1176">
<rect width="11" height="11" fill="white"/>
</clipPath>
</defs>
</svg>
`;
