import i18n from "i18next";
import { useEffect } from "react";
import { initReactI18next } from "react-i18next";

import { Navigate, Outlet, useParams } from "react-router-dom";

import { resources } from "../../data/locales/resources";

i18n.use(initReactI18next).init({
    resources: resources,
    lng: "en",
    fallbackLng: "en",
    interpolation: { escapeValue: false },
});

export function IncludeLocalization() {
    const { lang } = useParams();

    useEffect(() => {
        i18n.changeLanguage(lang);
    }, [lang]);

    return resources[lang] ? (
        <>
            <Outlet />
        </>
    ) : (
        <Navigate to="/en/" replace />
    );
}
