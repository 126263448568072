import React from "react";
import Select, { components } from "react-select";

import { ReactComponent as LanguagePlanet } from "../../../../static/images/language.svg";
import { ReactComponent as ArrowSVG } from "../../../../static/images/language_dropdown_arrow.svg";

import "./Language.scss";
import { useTranslation } from "react-i18next";

const options = [
    { value: "en", label: "EN" },
    { value: "es", label: "ES" },
    { value: "fr", label: "FR" },
];

const customStyles = {
    control: (provided) => ({
        ...provided,
        backgroundColor: "transparent",
        borderColor: "#FFFFFF",
        borderRadius: "7px",
        boxShadow: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:hover": {
            borderColor: "white",
        },
        height: "30px",
        minHeight: "0px",
        width: "75px",
        fontSize: "12px",
        fontFamily: "Open Sans",
        fontWeight: "700",
    }),
    singleValue: (provided) => ({
        ...provided,
        color: "#FFFFFF",
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: "0", // Adjust padding
        color: "white",
        "&:hover": {
            color: "white",
        },
    }),
    indicatorSeparator: () => ({
        display: "none", // Remove the separating line
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: "#00000053", // Semi-transparent background
        backdropFilter: "blur(10px)", // Apply blur effect
        borderRadius: "5px",
        marginTop: "5px",
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#ffffff5e" : "transparent",
        color: "#FFFFFF",
        transition: "0.1s ease-in",
        "&:hover": {
            backgroundColor: "#ffffffa4",
        },
        fontSize: "12px",
        fontFamily: "Open Sans",
        fontWeight: "700",
        height: "20px",
        display: "flex",
        alignItems: "center",
    }),
};

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <ArrowSVG style={{ marginRight: "8px" }} />
        </components.DropdownIndicator>
    );
};

const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
        <LanguagePlanet style={{ marginRight: "4px" }} />
        {children}
    </components.SingleValue>
);

function LanguageDropdown({ resources }) {
    const pathname = window.location.pathname;
    const currentLanguage = pathname.split("/")[1];

    const options = Object.entries(resources)
        .filter(([key]) => key !== currentLanguage) // Filter out the current language
        .map(([key, value]) => ({ value: key, label: value.title }));

    const handleChange = (selectedOption) => {
        // Logic to handle language change
        const newLanguage = selectedOption.value;
        window.location.href = `/${newLanguage}/`; // Redirect to the new language path
    };
    const defaultValue = {
        value: currentLanguage,
        label: resources[currentLanguage]?.title || "EN",
    };

    return (
        <div className="language-dropdown">
            <Select
                defaultValue={defaultValue}
                options={options}
                styles={customStyles}
                components={{ SingleValue, DropdownIndicator }}
                onChange={handleChange}
                isClearable={false}
                isSearchable={false}
            />
        </div>
    );
}

export default LanguageDropdown;
