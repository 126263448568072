import { BASE64_FONT_NAME, BASE64_FONT_WEIGHT, base64Font } from "./Base64Font";

const calculateTextWidth = (text, fontSize, fontFamily, fontWeight) => {
    const font = fontSize + "px " + fontFamily;

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = `${fontWeight} ${font}`;
    return context.measureText(text).width;
};

const MarkerTemplate = (
    totalWidth,
    height,
    fontFamily,
    fontSize,
    fontWeight,
    text,
    bgColor,
    textColor,
    icon,
    iconWidth,
    padding,
    label_gap
) => {
    let text_x;
    if (icon) {
        text_x = padding + iconWidth + label_gap;
    } else {
        text_x = padding;
    }

    return `
        <svg width="${totalWidth}" height="${height}" xmlns="http://www.w3.org/2000/svg">
            <style>
                @font-face {
                    font-family: ${fontFamily};
                    src: url(${base64Font}) format('truetype');
                    font-weight: ${fontWeight};
                }
            </style>
        
            <rect x="0" y="0" width="100%" height="100%" rx="5" ry="5" fill="${bgColor}" />
            
            <text 
                x="${text_x}"
                y="50%" 
                dominant-baseline="central" 
                font-family="${fontFamily}" 
                font-size="${fontSize}" 
                font-weight="${fontWeight}"
                fill="${textColor}"
            >
                ${text}
            </text>

            <g transform="translate(${padding}, ${(height - iconWidth) / 2})" >
                ${icon}
            </g>
        </svg>
    `;
};

const encodeSvgToDataUri = (svg) => {
    return `data:image/svg+xml;base64,${btoa(
        unescape(encodeURIComponent(svg))
    )}`;
};

export const generateSvg = (text, bgColor, textColor, align, icon) => {
    const fontSize = 11;
    const padding = 10;

    const iconWidth = 11;
    const labelGap = 5;

    const textWidth = calculateTextWidth(
        text,
        fontSize,
        BASE64_FONT_NAME,
        BASE64_FONT_WEIGHT
    );

    let totalWidth;
    if (icon) {
        totalWidth = textWidth + padding * 2 + iconWidth + labelGap;
    } else {
        totalWidth = textWidth + padding * 2;
    }
    const height = 22;

    const pinWidth = 12;
    const pinHeight = 20;
    const x_margin = 4;
    const y_margin = 2;

    let anchorX;
    let anchorY;

    if (align === "right") {
        anchorX = -(pinWidth / 2) - x_margin;
        anchorY = (height - pinHeight) / 2 + pinHeight;
    } else if (align === "left") {
        anchorX = pinWidth / 2 + x_margin + totalWidth;
        anchorY = (height - pinHeight) / 2 + pinHeight;
    } else if (align === "top") {
        anchorX = totalWidth / 2;
        anchorY = height + pinHeight + y_margin;
    } else if (align === "bottom") {
        anchorX = totalWidth / 2;
        anchorY = -y_margin;
    } else {
        anchorX = 0;
        anchorY = 0;
    }

    const markerIcon = {
        url: encodeSvgToDataUri(
            MarkerTemplate(
                totalWidth,
                height,
                BASE64_FONT_NAME,
                fontSize,
                BASE64_FONT_WEIGHT,
                text,
                bgColor,
                textColor,
                icon,
                iconWidth,
                padding,
                labelGap
            )
        ),
        anchor: new window.google.maps.Point(anchorX, anchorY),
    };

    return markerIcon;
};

export const loadCustomFont = () => {
    const font = new FontFace(BASE64_FONT_NAME, `url(${base64Font})`, {
        weight: BASE64_FONT_WEIGHT,
    });
    return font.load().then((loadedFont) => {
        document.fonts.add(loadedFont);
        return loadedFont;
    });
};
