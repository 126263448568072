export const translationsRu = {
    menu_developments: "Застройки",
    menu_developments_nakheel: "Nakheel",
    menu_developments_meydan: "Meydan",

    menu_project_type: "Тип проекта",
    menu_project_type_villas: "Виллы",
    menu_project_type_apartments: "Квартиры",
    menu_project_type_plots: "Участки",
    menu_project_type_apartments_villas: "Квартиры и Виллы",
    menu_project_type_plots_villas_apartments: "Участки и Виллы и Квартиры",
    menu_project_type_show_all: "Показать все",

    menu_project_infrastructure: "Инфраструктура",
    menu_project_infrastructure_landmarks: "Достопримечательности",
    menu_project_infrastructure_transport: "Транспорт (Метро)",
    menu_project_infrastructure_education: "Образование",
    menu_project_infrastructure_health: "Здоровье",
    menu_project_infrastructure_retail_or_shopping:
        "Розничная торговля или Магазины",

    menu_show_master_communities: "Мастер-комьюнити",
    menu_show_distances: "Показать расстояние",
    menu_show_circles: "Показать радиусы",
    menu_new_projects: "Новые проекты",

    map_minutes: "мин",
    map_kilometers: "км",

    card_gallery: "Галерея",
    card_video: "Видео",
    card_floor_plans: "Поэтажные планы",
    card_brochure: "Брошюра",
    card_guidelines: "Гайдлайн",
    card_plots: "Участки",
    card_mat_board: "Брошюра по материалам",
    card_mat_board_brochure: "Брошюра по материалам",
    card_masterplan: "Генеральный план",
    card_fact_sheet: "Информационный бюллетень",
    card_payment_plan: "Платежный план",
    card_floor_plans_and_brochure: "Поэтажные планы и Брошюра",

    canal_front_residences: "Жилой Комплекс Canal Front",

    palm_jebel_ali: "Пальма Jebel Ali",
    palm_jebel_ali_desc:
        "Новый футуристический комплекс Palm Jebel Ali поднимет мировой стандарт жизни на воде, отражая обширные зеленые зоны и роскошные удобства для жизни. Он будет включать в себя многофункциональные пешеходные кварталы,  технологии умного города, а также обеспечит разнообразные возможности передвижения для жителей, посетителей и местных групп населения",
    palm_jebel_ali_plc: "PLC",
    palm_jebel_ali_dcr: "DCR",
    palm_jebel_ali_ukl: "UKL",
    palm_jebel_ali_mnop: "MNOP",

    veneto: "Район Veneto",
    palm_jumeirah: "Пальма Jumeirah",

    como_residences: "Резиденции Como",
    como_residences_desc:
        "Являясь частью знаменитого бренда Como Hotels and Resorts, комплекс Como Residences может похвастаться непревзойденными удобствами и услугами, созданными для того, чтобы обеспечить жильцам проживание мирового класса. Из потрясающих высотных башен открываются захватывающие виды на горизонт Дубая.",

    the_palm_tower_residences: "Резиденции The Palm Tower",
    st_regis_dubai: "Отель St. Regis – Пальма Jumeirah",

    palm_beach_towers: "Башни Palm Beach",
    palm_beach_towers_desc:
        "Расположенные у входа в Palm Jumeirah, роскошные жилые башни предлагают резиденции с потрясающими видами на 360° и разнообразные близлежащие рестораны, места отдыха и развлечений. Здесь предлагается выбор резиденций с одной, двумя и тремя спальнями, полностью меблированных и предлагающих широкий спектр самых современных удобств, дополняющих ультрасовременную современную жизнь. Каждая резиденция в Palm Beach Towers предлагает освещенные солнцем пространства, освещение мирового класса и виды на живописный пляж.",
    palm_beach_towers_tower_1: "Небоскреб 1",
    palm_beach_towers_tower_2: "Небоскреб 2",
    palm_beach_towers_tower_3: "Небоскреб 3",

    jumeirah_village_triangle: "Район Jumeirah Village Triangle",
    jumeirah_village_circle: "Район Jumeirah Village Circle",
    jumeirah_heights: "Район Jumeirah Heights",
    jumeirah_islands: "Острова Jumeirah",
    jumeirah_park: "Парк Jumeirah",
    discovery_gardens: "Район Discovery Gardens",
    masakin_al_furjan: "Район Masakin Al Furjan",
    al_furjan: "Район Al Furjan",
    murooj_al_furjan: "Коттеджный поселок Murooj Al Furjan",

    jebel_ali_village: "Коттеджный поселок Jebel Ali Village",
    jebel_ali_village_desc: "",
    jebel_ali_village_masterplan_a3: "MASTERPLAN A3",

    tilal_al_furjan: "Коттеджный поселок Tilal Al Furjan",
    tilal_al_furjan_desc: "",

    the_gardens: "Район Gardens",
    jebel_ali_club: "Клаб-хаус Jebel Ali",
    the_world: "Острова «Карта Мира»",

    dubai_islands: "Острова Dubai Islands",
    dubai_islands_desc: "",
    dubai_islands_island_a: "DUBAI ISLAND A",

    rixos: "Жилой Комплекс Rixos Dubai Islands Hotes and Residences",
    rixos_desc:
        "Жилой Комплекс Rixos Dubai Islands Hotes & Residences - это комплекс премиум-класса, предлагающий проживание на берегу моря на островах Дубая. Среди предлагаемых вариантов - апартаменты с 1-3 спальнями, пляжные дома с 2-3 спальнями и ограниченная коллекция отдельных вилл. Удобства, основные услуги и услуги a la carte в Rixos Dubai Islands Hotel & Residences от Nakheel позволят вам погрузиться в курортную жизнь.",
    rixos_a1: "A1",
    rixos_a2: "A2",
    rixos_a3: "A3",
    rixos_a4: "A4",
    rixos_beach_houses: "Beach Houses",
    rixos_villas: "Villas",

    greenwood: "Район Greenwood",
    greenwood_desc:
        "Greenwood в Дубае - это передовой городской проект, воплощающий в себе инновации, устойчивость и роскошь в самом сердце динамичного города. Этот раскинувшийся оазис, тщательно спроектированный так, чтобы гармонировать с окружающим пустынным ландшафтом, переосмысливает современную жизнь благодаря сочетанию передовой архитектуры, зеленых насаждений и самых современных удобств.",

    warsan_village: "Район Warsan",
    international_city: "Район International City",

    district_11: "Район Дистрикт 11",
    district_11_desc:
        "Район 11 Opal Gardens оживляет атмосферу благодаря зеленым насаждениям, окружающим потрясающую хрустальную лагуну. Охраняемый и закрытый комплекс включает в себя хорошо продуманные виллы и таунхаусы, окруженные пышным ландшафтным дизайном и более чем 5-километровыми велосипедными и пешеходными дорожками. Жилые дома в District 11 Opal Gardens отличаются безупречным дизайном и потрясающими видами на горизонт Дубая и хрустальную лагуну.",

    nad_al_sheba_villas: "Коттеджный поселок Nad Al Sheba",

    district_one_west: "Коттеджный поселок District One West",
    district_one_west_desc:
        "Эпический стиль жизни в Коттеджном поселке District One West в MBR City, Дубай, представлен компанией Nakheel, чтобы стимулировать новую эру развития, предлагая виллы с 4 и 5 спальнями и особняки с 6 и 7 спальнями. Приезжайте и откройте для себя самый высокий уровень роскоши в одном из самых престижных районов Дубая. Ощутите необыкновенный стиль жизни в возвышенном, закрытом сообществе с эпической коллекцией безупречной недвижимости и элитных домов для их жильцов.",

    golf_course: "Гольф поля",
    meydan_hotel: "Отель Мейдан и Ипподром",
    polo_residence: "Резиденции Поло",

    meydan_avenue: "Мейдан Авеню",
    meydan_avenue_desc: "",
    meydan_avenue_plot_a: "Участок А",
    meydan_avenue_plot_b: "Участок B",
    meydan_avenue_plot_c: "Участок C",
    meydan_avenue_plot_design: "Дизайн участка",
    meydan_avenue_plot_dev_control: "Регулирования застройки",
    meydan_avenue_plot_brochure: "Брошюра",

    lagoon_views: "Резиденция Lagoon Views",
    lagoon_views_desc:
        "Зеркальное отражение сияющего великолепия кристально голубых вод, роскошная жилая башня Lagoon Views в District One спроектирована таким образом, чтобы предложить взыскательным покупателям поистине незабываемые впечатления от проживания. В этой самой высокой башне District One Residences представлены апартаменты с одной, двумя, тремя и четырьмя спальнями, а также пентхаусы с пятью спальнями и обширными террасами с видом на спокойную лагуну.",

    district_one:
        "Мохамед Бин Рашид Аль Мактум Сити – коттеджный поселок District One",

    naya_residence: "Резиденции Найя",
    naya_residence_desc: "",
    naya_residence_1_floor: "Naya 1 Floor",
    naya_residence_2_floor: "Naya 2 Floor",
    naya_residence_3_floor: "Naya 3 Floor",
    naya_residence_villas: "Villas",

    nakheel_sales: "Центр продаж Nakheel",
    jumeirah_beach: "Джумейра-Бич",
    dwc_airport: "Аэропорт DWC",
    dxb_airport: "Аэропорт DXB",
    the_view_at_the_palm: "Вид на отель The Palm",
    palm_monorail: "Монорельс",
    nakheel_marinas: "Нахиль Маринас Дубайские острова",
    dubai_canal: "Канал Дубая",
};
