export const dropdownsData = (
    checkboxFiltersRadio,
    setCheckboxFiltersRadio,
    t
) => {
    return [
        {
            type: "checkbox",
            title: t("menu_developments"),
            id: "developments",
            elements: [
                {
                    text: t("menu_developments_nakheel"),
                    id: "nakheel",
                },
                {
                    text: t("menu_developments_meydan"),
                    id: "meydan",
                },
            ],
            stateObject: {
                state: checkboxFiltersRadio,
                setState: setCheckboxFiltersRadio,
            },
        },
        {
            type: "checkbox",
            title: t("menu_project_type"),
            id: "project-type",
            elements: [
                {
                    text: t("menu_project_type_villas"),
                    id: "villas",
                },
                {
                    text: t("menu_project_type_apartments"),
                    id: "apartments",
                },
                {
                    text: t("menu_project_type_plots"),
                    id: "plots",
                },
                {
                    text: t("menu_project_type_apartments_villas"),
                    id: "apartments_villas",
                },
                {
                    text: t("menu_project_type_plots_villas_apartments"),
                    id: "plots_villas_apartments",
                },
                {
                    text: t("menu_project_type_show_all"),
                    id: "_all_project_type",
                },
            ],
            stateObject: {
                state: checkboxFiltersRadio,
                setState: setCheckboxFiltersRadio,
            },
        },
        {
            type: "checkbox",
            title: t("menu_project_infrastructure"),
            id: "infrastructure",
            elements: [
                {
                    text: t("menu_project_infrastructure_landmarks"),
                    id: "landmarks",
                },
                {
                    text: t("menu_project_infrastructure_transport"),
                    id: "transport",
                },
                {
                    text: t("menu_project_infrastructure_education"),
                    id: "education",
                },
                {
                    text: t("menu_project_infrastructure_health"),
                    id: "health",
                },
                {
                    text: t("menu_project_infrastructure_retail_or_shopping"),
                    id: "retail_or_shopping",
                },
                {
                    text: t("menu_project_type_show_all"),
                    id: "_all_infrastructure",
                },
            ],
            stateObject: {
                state: checkboxFiltersRadio,
                setState: setCheckboxFiltersRadio,
            },
        },
    ];
};
