export const translationsAr = {
    menu_developments: "المشاريع",
    menu_developments_nakheel: "نخيل",
    menu_developments_meydan: "ميدان",

    menu_project_type: "نوعية المشاريع",
    menu_project_type_villas: "فلل",
    menu_project_type_apartments: "شقق",
    menu_project_type_plots: "أراضي",
    menu_project_type_apartments_villas: "شقق وفلل",
    menu_project_type_plots_villas_apartments: "أراضي وفلل وشقق",
    menu_project_type_show_all: "عرض الكل",

    menu_project_infrastructure: "البنية التحتية",
    menu_project_infrastructure_landmarks: "معالم",
    menu_project_infrastructure_transport: "المواصلات (مترو)",
    menu_project_infrastructure_education: "التعليم",
    menu_project_infrastructure_health: "الصحة ",
    menu_project_infrastructure_retail_or_shopping: "التجزئة والتسوق",

    menu_show_master_communities: "المجتمعات الرئيسية",
    menu_show_distances: "عرض المسافة",
    menu_show_circles: "عرض الدوائر",
    menu_new_projects: "المشاريع الجديدة",

    map_minutes: "دقيقة (دقائق)",
    map_kilometers: "كلم (كيلومترات)",

    card_gallery: "معرض الصور",
    card_video: "الفيديو",
    card_floor_plans: "مخططات الطوابق",
    card_brochure: "كتيب المعلومات",
    card_guidelines: "الإرشادات",
    card_plots: "الأراضي",
    card_mat_board: "كتيب لوحة المواد",
    card_mat_board_brochure: "كتيب لوحة المواد",
    card_masterplan: "المخطط الرئيسي",
    card_fact_sheet: "وثيقة الحقائق ",
    card_payment_plan: "خطة الدفع",
    card_floor_plans_and_brochure: "مخططات الطوابق وكتيب المعلومات",

    canal_front_residences: "قنال فرونت ريزيدنسز",

    palm_jebel_ali: "نخلة جبل علي",
    palm_jebel_ali_desc:
        "The new, futuristic Palm Jebel Ali will raise the global benchmark in waterfront living, featuring extensive green spaces and luxury lifestyle amenities. It will feature mixed-use walkable neighbourhoods and incorporate smart city technologies and provide a diverse range of mobility options for residents, visitors and communities.",
    palm_jebel_ali_plc: "PLC",
    palm_jebel_ali_dcr: "DCR",
    palm_jebel_ali_ukl: "UKL",
    palm_jebel_ali_mnop: "MNOP",

    veneto: "فينيتو",
    palm_jumeirah: "نخلة جميرا",

    como_residences: "كومو ريزيدنسز",
    como_residences_desc:
        "كجزء من العلامة التجارية الشهيرة لفنادق ومنتجعات كومو، يمتاز كومو ريزيدنسز بوسائل الراحة والخدمات التي لا مثيل لها والمصممة خصيصاً لتزويد المقيمين فيه بتجربة معيشية عالمية المستوى. وتوفر الأبراج الشاهقة المذهلة إطلالات خلابة على أفق دبي.",

    the_palm_tower_residences: "ذا بالم تاور ريزيدنسز",
    st_regis_dubai: "سانت ريجيس دبي ــ ذا بالم",

    palm_beach_towers: "أبراج بالم بيتش",
    palm_beach_towers_desc: "",
    palm_beach_towers_tower_1: "البرج 1",
    palm_beach_towers_tower_2: "البرج 2",
    palm_beach_towers_tower_3: "البرج 3",

    jumeirah_village_triangle: "قرية جميرا تراينجل",
    jumeirah_village_circle: "قرية جميرا سيركل",
    jumeirah_heights: "مرتفعات جميرا",
    jumeirah_islands: "جزر جميرا",
    jumeirah_park: "جميرا بارك",
    discovery_gardens: "ديسكفري جاردنز",
    masakin_al_furjan: "مساكن الفرجان",
    al_furjan: "الفرجان",
    murooj_al_furjan: "مروج الفرجان",

    jebel_ali_village: "قرية جبل علي",
    jebel_ali_village_desc: "",
    jebel_ali_village_masterplan_a3: "MASTERPLAN A3",

    tilal_al_furjan: "تلال الفرجان",
    tilal_al_furjan_desc: "",

    the_gardens: "ذا جاردنز",
    jebel_ali_club: "نادي جبل علي",
    the_world: "العالم",

    dubai_islands: "جزر دبي",
    dubai_islands_desc: "",
    dubai_islands_island_a: "DUBAI ISLAND A",

    rixos: "فندق ومساكن ريكسوس جزر دبي",
    rixos_desc:
        "فندق ومساكن ريكسوس جزر دبي هو عبارة عن مشروع مميز يوفر مساكن على الواجهة البحرية في جزر دبي. وتتنوع المساكن المعروضة شقق من 1 إلى 3 غرف نوم ومنازل شاطئية من 2 إلى 3 غرف نوم ومجموعة محدودة من الفلل المستقلة. وستوفر لك المرافق السكنية والخدمات الأساسية والانتقائية في فندق ومساكن ريكسوس جزر دبي من نخيل حياة مستوحاة من المنتجعات.",
    rixos_a1: "A1",
    rixos_a2: "A2",
    rixos_a3: "A3",
    rixos_a4: "A4",
    rixos_beach_houses: "Beach Houses",
    rixos_villas: "Villas",

    greenwood: "الغابة الخضراء",
    greenwood_desc:
        "الغابة الخضراء في دبي عبارة عن مشروع حضري يحمل رؤية تتجسد في الابتكار والاستدامة والرفاهية في قلب هذه المدينة الديناميكية. تم تصميم هذه الواحة المترامية الأطراف بعناية لتتناغم مع المناظر الطبيعية الصحراوية المحيطة بها، لتعيد تعريف الحياة العصرية من خلال تناغم الهندسة المعمارية المتطورة والمساحات الخضراء ووسائل الراحة الحديثة.",

    warsan_village: "ورسان",
    international_city: "المدينة العالمية",

    district_11: "الحي الأول",
    district_11_desc: "",

    nad_al_sheba_villas: "فلل ند الشيا",

    district_one_west: "الحي الأول ويست",
    district_one_west_desc:
        "أسلوب حياة فاخر في الحي الأول ويست ضمن مدينة محمد بن راشد آل مكتوم بدبي من نخيل لحقبة جديدة من التطوير، يوفر المشروع فلل من 4 و5 غرف نوم وقصور من 6 و7 غرف نوم. اكتشف مستوى جديداً من الرفاهية في واحدة من أكثر المجتمعات السكنية المرموقة في دبي. جرب نمط الحياة الاستثنائي في مجتمع راقي مسور مع مجموعة راقية من العقارات الفاخرة والمنازل المتميزة للمقيمين.",

    golf_course: "ملعب الجولف",
    meydan_hotel: "فندق ومضمار ميدان",
    polo_residence: "بولو ريزيدنس",

    meydan_avenue: "ميدان أفينيو",
    meydan_avenue_desc: "",
    meydan_avenue_plot_a: "الأرض A",
    meydan_avenue_plot_b: "الأرض B",
    meydan_avenue_plot_c: "الأرض C",
    meydan_avenue_plot_design: "تصميم الأرض",
    meydan_avenue_plot_dev_control: "رقابة التطوير",
    meydan_avenue_plot_brochure: "كتيب المعلومات",

    lagoon_views: "لاجون فيوز",
    lagoon_views_desc: "",

    district_one: "مدينة محمد بن راشد آل مكتوم - الحي الأول",

    naya_residence: "نايا ريزيدنسز",
    naya_residence_desc: "",
    naya_residence_1_floor: "Naya 1 Floor",
    naya_residence_2_floor: "Naya 2 Floor",
    naya_residence_3_floor: "Naya 3 Floor",
    naya_residence_villas: "Villas",

    nakheel_sales: "مركز مبيعات نخيل",
    jumeirah_beach: "شاطئ الجميرا",
    dwc_airport: "مطار آل مكتوم الدولي ",
    dxb_airport: "مطار دبي الدولي",
    the_view_at_the_palm: "ذا ڤيو آت ذا بالم",
    palm_monorail: "ذا بالم مونوريل",
    nakheel_marinas: "مراسي نخيل جزر دبي",
    dubai_canal: "قناة دبي",
};
