import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useJsApiLoader } from "@react-google-maps/api";

import "./Main.scss";
import "../../styles/main/Animations.scss";
import "../../styles/main/Adaptations.scss";

import Loading from "../../components/main/loading/Loading";

import Menu from "../../components/main/menu/Menu";
import BottomTips from "../../components/main/bottom-tips/BottomTips";

import Map from "../../components/main/map/Map";

import { dropdownsData } from "./HeaderData";
import Card from "../../components/main/card/Card";
import WindowCard from "../../components/main/window-card/WindowCard";

export default function Main() {
    const [t] = useTranslation();

    // Dropdowns data
    const [checkboxFiltersRadio, setCheckboxFiltersRadio] = useState([
        "nakheel",
        "meydan",
        "plots",
        "villas",
        "apartments",
        "apartments_villas",
        "plots_villas_apartments",
        "_all_project_type",
        "landmarks",
    ]);

    const data = dropdownsData(
        checkboxFiltersRadio,
        setCheckboxFiltersRadio,
        t
    );

    const [directionSwitchChecked, setDirectionSwitchChecked] = useState(false);
    const [radiusSwitchChecked, setRadiusSwitchChecked] = useState(false);
    const [newProjectsSwitchChecked, setNewProjectsSwitchChecked] =
        useState(false);
    const [masterCommunitiesChecked, setMasterCommunitiesChecked] =
        useState(true);
    const [mapTypeId, setMapTypeId] = useState("satellite");

    // Map data
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyDqb2XlJJbdHDsu8mDDz4oAAMdevM011bI",
    });

    // Card and Window
    const [activeCard, setActiveCard] = useState(false);
    const [cardContent, setCardContent] = useState({
        title: null,
        desc: null,
        previews: [],
    });
    const [activeWindow, setActiveWindow] = useState(false);
    const [windowContent, setWindowContent] = useState(<h1>qweq</h1>);

    return (
        <div className="main">
            <Loading isLoadedMap={isLoaded} />
            <WindowCard
                windowContent={windowContent}
                setWindowContent={setWindowContent}
                activeWindow={activeWindow}
                setActiveWindow={setActiveWindow}
                setActiveCard={setActiveCard}
            />
            <Card
                activeCard={activeCard}
                setActiveCard={setActiveCard}
                cardContent={cardContent}
                activeWindow={activeWindow}
                setActiveWindow={setActiveWindow}
                setWindowContent={setWindowContent}
            />
            <div className="main-first">
                <Menu
                    data={data}
                    directionSwitchChecked={directionSwitchChecked}
                    setDirectionSwitchChecked={setDirectionSwitchChecked}
                    radiusSwitchChecked={radiusSwitchChecked}
                    setRadiusSwitchChecked={setRadiusSwitchChecked}
                    newProjectsSwitchChecked={newProjectsSwitchChecked}
                    setNewProjectsSwitchChecked={setNewProjectsSwitchChecked}
                    masterCommunitiesChecked={masterCommunitiesChecked}
                    setMasterCommunitiesChecked={setMasterCommunitiesChecked}
                    setActiveCard={setActiveCard}
                    mapTypeId={mapTypeId}
                />
                <BottomTips
                    checkboxFiltersRadio={checkboxFiltersRadio}
                    mapTypeId={mapTypeId}
                    setMapTypeId={setMapTypeId}
                />
            </div>
            <div className="main-second">
                <Map
                    isLoaded={isLoaded}
                    checkboxFiltersRadio={checkboxFiltersRadio}
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                    setCardContent={setCardContent}
                    activeWindow={activeWindow}
                    setActiveWindow={setActiveWindow}
                    setWindowContent={setWindowContent}
                    directionSwitchChecked={directionSwitchChecked}
                    setDirectionSwitchChecked={setDirectionSwitchChecked}
                    radiusSwitchChecked={radiusSwitchChecked}
                    setRadiusSwitchChecked={setRadiusSwitchChecked}
                    newProjectsSwitchChecked={newProjectsSwitchChecked}
                    masterCommunitiesChecked={masterCommunitiesChecked}
                    mapTypeId={mapTypeId}
                />
            </div>
        </div>
    );
}
