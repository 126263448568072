import { FlagIcon } from "./marker/icons/FlagIcon";
import { PlaneIcon } from "./marker/icons/PlaneIcon";

import {
    BLUE_BG_COLOR,
    WHITE_BG_COLOR,
    GREY_BG_COLOR,
    BLACK_TEXT_COLOR,
    WHITE_TEXT_COLOR,
    GREY_TEXT_COLOR,
    LIGHT_GREY_BG_COLOR,
} from "./marker/config";

import MarkHospital from "../../../static/images/mark-hospital-small.svg";
import MarkSchool from "../../../static/images/mark-school-small.svg";
import MarkShopping from "../../../static/images/mark-shopping-small.svg";

import MarkVenetoBlack from "../../../static/images/markers/veneto/mark-veneto-black.svg";

import DubaiIslandsPreview1 from "../../../static/images/markers/dubai-islands/nakheel_dubai_islands_deira_properties_for_sale_1.jpg";
import DubaiIslandsPreview2 from "../../../static/images/markers/dubai-islands/nakheel_dubai_islands_deira_properties_for_sale_4.jpg";
import DubaiIslandsVideo from "../../../static/images/markers/dubai-islands/Dubai Islands Video.mp4";

import JebelAliVillageVideo from "../../../static/images/markers/jebel-ali-village/Jebel Ali Village.mp4";

import NayaResidenceVideo from "../../../static/images/markers/naya-residence/video.mp4";

import TilalAlFurjanVideo from "../../../static/images/markers/tilal-al-furjan/Video.mp4";
import TilalAlFurjanPreview1 from "../../../static/images/markers/tilal-al-furjan/Renders/4B-A_Back_HC_RT5.jpg";
import TilalAlFurjanPreview2 from "../../../static/images/markers/tilal-al-furjan/Renders/4B-B_Back_HC_RT5.jpg";
import TilalAlFurjanPreview3 from "../../../static/images/markers/tilal-al-furjan/Renders/5B-B_Front_HC_RT4.jpg";
import TilalAlFurjanPreview4 from "../../../static/images/markers/tilal-al-furjan/Renders/5B-C_Front_HC_RT4.jpg";
import TilalAlFurjanPreview5 from "../../../static/images/markers/tilal-al-furjan/Renders/LIVING_4B-F_HC_RT4.jpg";

import TilalAlFurjanBrochure1 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_01.jpg";
import TilalAlFurjanBrochure2 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_02.jpg";
import TilalAlFurjanBrochure3 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_03.jpg";
import TilalAlFurjanBrochure4 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_04.jpg";
import TilalAlFurjanBrochure5 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_05.jpg";
import TilalAlFurjanBrochure6 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_06.jpg";
import TilalAlFurjanBrochure7 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_07.jpg";
import TilalAlFurjanBrochure8 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_08.jpg";
import TilalAlFurjanBrochure9 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_09.jpg";
import TilalAlFurjanBrochure10 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_10.jpg";
import TilalAlFurjanBrochure11 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_11.jpg";
import TilalAlFurjanBrochure12 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_12.jpg";
import TilalAlFurjanBrochure13 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_13.jpg";
import TilalAlFurjanBrochure14 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_14.jpg";
import TilalAlFurjanBrochure15 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_15.jpg";

import TilalAlFurjanFactSheet1 from "../../../static/images/files/Tilal Al Furjan/b. FactSheet/Fact Sheet_Page_1.jpg";
import TilalAlFurjanFactSheet2 from "../../../static/images/files/Tilal Al Furjan/b. FactSheet/Fact Sheet_Page_2.jpg";
import TilalAlFurjanFactSheet3 from "../../../static/images/files/Tilal Al Furjan/b. FactSheet/Fact Sheet_Page_3.jpg";

import LagoonViewsVideo from "../../../static/images/markers/lagoon-views/LV_Video_VO_Final_EN.mp4";

import GreenwoodVideo from "../../../static/images/markers/greenwood/Greenwood_4.mp4";

import RixosVideo from "../../../static/images/markers/rixos/Rixos Video.mp4";

import DistrictOneVideo from "../../../static/images/markers/district-one-west-1/d1west_long_shortfilm_2_rev03.mp4";

import VideoComoResidences from "../../../static/images/markers/como-residences/Como_Residences_Animation.mp4";

import PalmBeachTowersVideo from "../../../static/images/markers/palm-beach-towers/video.mp4";

import CanalFrontResidencesVideo from "../../../static/images/markers/canal-front-residences/Canal Front Residence 6 Video.mp4";

import MarkBus1 from "../../../static/images/mark-bus-1.svg";
import MarkBus2 from "../../../static/images/mark-bus-2.svg";
import MarkBus3 from "../../../static/images/mark-bus-3.svg";

import {
    // Infrastructure
    schools,
    hospitals,
    retails,

    // Polygons
    dubai_islands,
    palm_jumeirah_1,
    palm_jumeirah_2,
    palm_jebel_ali_1,
    palm_jebel_ali_2,
    palm_jebel_ali_3,
    the_gardens,
    jumeirah_village_circle,
    jumeirah_village_triangle,
    canal_front_residences,
    district_one,
    district_11,

    // Polylines
    bus_road_1,
    bus_road_2,
    bus_road_3,
    bus_road_1_stops,
    bus_road_2_stops,
    bus_road_3_stops,
    jumeirah_heights,
    international_city_1,
    international_city_2,
    the_world_pol_1,
    the_world_pol_2,
    the_world_pol_3,
    the_world_pol_4,
    the_world_pol_5,
    the_world_pol_6,
    the_world_pol_7,
    the_world_pol_8,
    the_world_pol_9,
    the_world_pol_10,
    the_world_pol_11,
    the_world_pol_12,
    the_world_pol_13,
    the_world_pol_14,
    the_world_pol_15,
    the_world_pol_16,
    the_world_pol_17,
    the_world_pol_18,
    the_world_pol_19,
    the_world_pol_20,
    the_world_pol_21,
    the_world_pol_22,
    the_world_pol_23,
    the_world_pol_24,
    the_world_pol_25,
    the_world_pol_26,
    the_world_pol_27,
    the_world_pol_28,
    the_world_pol_29,
    the_world_pol_30,
    the_world_pol_31,
    the_world_pol_32,
    the_world_pol_33,
    the_world_pol_34,
    the_world_pol_35,
    the_world_pol_36,
    the_world_pol_37,
    the_world_pol_38,
    the_world_pol_39,
    the_world_pol_40,
    the_world_pol_41,
    the_world_pol_42,
    the_world_pol_43,
    the_world_pol_44,
    the_world_pol_45,
    the_world_pol_46,
    the_world_pol_47,
    the_world_pol_48,
    the_world_pol_49,
    the_world_pol_50,
    the_world_pol_51,
    the_world_pol_52,
    the_world_pol_53,
    the_world_pol_54,
    the_world_pol_55,
    the_world_pol_56,
    the_world_pol_57,
    the_world_pol_58,
    the_world_pol_59,
    the_world_pol_60,
    the_world_pol_61,
    the_world_pol_62,
    the_world_pol_63,
    the_world_pol_64,
    the_world_pol_65,
    the_world_pol_66,
    the_world_pol_67,
    the_world_pol_68,
    the_world_pol_69,
    the_world_pol_70,
    the_world_pol_71,
    the_world_pol_72,
    the_world_pol_73,
    the_world_pol_74,
    the_world_pol_75,
    the_world_pol_76,
    the_world_pol_77,
    the_world_pol_78,
    the_world_pol_79,
    the_world_pol_80,
    the_world_pol_81,
    the_world_pol_82,
    the_world_pol_83,
    the_world_pol_84,
    the_world_pol_85,
    the_world_pol_86,
    the_world_pol_87,
    the_world_pol_88,
    the_world_pol_89,
    the_world_pol_90,
    the_world_pol_91,
    the_world_pol_92,
    the_world_pol_93,
    the_world_pol_94,
    the_world_pol_95,
    the_world_pol_96,
    the_world_pol_97,
    the_world_pol_98,
    the_world_pol_99,
    the_world_pol_100,
    the_world_pol_101,
    the_world_pol_102,
    the_world_pol_103,
    the_world_pol_104,
    the_world_pol_105,
    the_world_pol_106,
    the_world_pol_107,
    the_world_pol_108,
    the_world_pol_109,
    the_world_pol_110,
    the_world_pol_111,
    the_world_pol_112,
    the_world_pol_113,
    the_world_pol_114,
    the_world_pol_115,
    the_world_pol_116,
    the_world_pol_117,
    the_world_pol_118,
    the_world_pol_119,
    the_world_pol_120,
    the_world_pol_121,
    the_world_pol_122,
    the_world_pol_123,
    the_world_pol_124,
    the_world_pol_125,
    the_world_pol_126,
    the_world_pol_127,
    the_world_pol_128,
    the_world_pol_129,
    the_world_pol_130,
    the_world_pol_131,
    the_world_pol_132,
    the_world_pol_133,
    the_world_pol_134,
    the_world_pol_135,
    the_world_pol_136,
    the_world_pol_137,
    the_world_pol_138,
    the_world_pol_139,
    the_world_pol_140,
    the_world_pol_141,
    the_world_pol_142,
    the_world_pol_143,
    the_world_pol_144,
    the_world_pol_145,
    the_world_pol_146,
    the_world_pol_147,
    the_world_pol_148,
    the_world_pol_149,
    the_world_pol_150,
    the_world_pol_151,
    the_world_pol_152,
    the_world_pol_153,
    the_world_pol_154,
    the_world_pol_155,
    the_world_pol_156,
    the_world_pol_157,
    the_world_pol_158,
    the_world_pol_159,
    the_world_pol_160,
    the_world_pol_161,
    the_world_pol_162,
    the_world_pol_163,
    the_world_pol_164,
    the_world_pol_165,
    the_world_pol_166,
    the_world_pol_167,
    the_world_pol_168,
    the_world_pol_169,
    the_world_pol_170,
    the_world_pol_171,
    the_world_pol_172,
    the_world_pol_173,
    the_world_pol_174,
    the_world_pol_175,
    the_world_pol_176,
    the_world_pol_177,
    the_world_pol_178,
    the_world_pol_179,
    the_world_pol_180,
    the_world_pol_181,
    the_world_pol_182,
    the_world_pol_183,
    the_world_pol_184,
    the_world_pol_185,
    the_world_pol_186,
    the_world_pol_187,
    the_world_pol_188,
    the_world_pol_189,
    the_world_pol_190,
    the_world_pol_191,
    the_world_pol_192,
    the_world_pol_193,
    the_world_pol_194,
    the_world_pol_195,
    the_world_pol_196,
    the_world_pol_197,
    the_world_pol_198,
    the_world_pol_199,
    the_world_pol_200,
    the_world_pol_201,
    the_world_pol_202,
    the_world_pol_203,
    the_world_pol_204,
    bus_road_1_2,
} from "./Coords";

export const infrastrMarkers = [
    {
        id: "health",
        allCoords: hospitals,
        icon: MarkHospital,
    },
    {
        id: "education",
        allCoords: schools,
        icon: MarkSchool,
    },
    {
        id: "retail_or_shopping",
        allCoords: retails,
        icon: MarkShopping,
    },
];

export const markers = [
    {
        id: "the_gardens",
        checkIsInGroup: true,
        groupName: ["nakheel", "plots"],
        zoomLevel: null,
        coords: { lat: 25.0423900606047, lng: 55.1279246642445 },
        polygonCoords: [the_gardens],
        icon: {
            label: "the_gardens",
            bgColor: BLUE_BG_COLOR,
            textColor: WHITE_TEXT_COLOR,
            align: "top",
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "palm_jebel_ali",
        checkIsInGroup: true,
        groupName: ["nakheel", "plots", "villas"],
        zoomLevel: null,
        coords: { lat: 24.9875968817914, lng: 55.0025720069105 },
        polygonCoords: [palm_jebel_ali_1, palm_jebel_ali_2, palm_jebel_ali_3],
        icon: {
            label: "palm_jebel_ali",
            bgColor: BLUE_BG_COLOR,
            textColor: WHITE_TEXT_COLOR,
            align: "top",
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            title: "palm_jebel_ali",
            previews: [
                "/static/images/palm_jebel_ali/previews/1.jpg",
                "/static/images/palm_jebel_ali/previews/2.jpg",
                "/static/images/palm_jebel_ali/previews/3.jpg",
                "/static/images/palm_jebel_ali/previews/4.jpg",
                "/static/images/palm_jebel_ali/previews/5.jpg",
                "/static/images/palm_jebel_ali/previews/6.jpg",
                "/static/images/palm_jebel_ali/previews/7.jpg",
                "/static/images/palm_jebel_ali/previews/8.jpg",
                "/static/images/palm_jebel_ali/previews/9.jpg",
            ],
            desc: "palm_jebel_ali_desc",
            link: null,
            video: null,
            buttons: [
                {
                    type: "double",
                    data: [
                        {
                            type: "img",
                            text: "card_guidelines",
                            data: [
                                {
                                    images_path:
                                        "/static/images/palm_jebel_ali/guidelines/",
                                    images_number: 32,
                                },
                            ],
                        },
                        {
                            type: "img",
                            text: "card_plots",
                            data: [
                                {
                                    title: "palm_jebel_ali_plc",
                                    images_path:
                                        "/static/images/palm_jebel_ali/plc/",
                                    images_number: 20,
                                },
                                {
                                    title: "palm_jebel_ali_dcr",
                                    images_path:
                                        "/static/images/palm_jebel_ali/dcr/",
                                    images_number: 23,
                                },
                            ],
                        },
                    ],
                },
                {
                    type: "double",
                    data: [
                        {
                            type: "img",
                            text: "card_mat_board",
                            data: [
                                {
                                    images_path:
                                        "/static/images/palm_jebel_ali/mat_board/",
                                    images_number: 67,
                                },
                            ],
                        },
                        {
                            type: "img",
                            text: "card_brochure",
                            data: [
                                {
                                    images_path:
                                        "/static/images/palm_jebel_ali/brochure/",
                                    images_number: 34,
                                },
                            ],
                        },
                    ],
                },
                {
                    type: "double",
                    data: [
                        {
                            type: "img",
                            text: "card_floor_plans",
                            data: [
                                {
                                    title: "palm_jebel_ali_ukl",
                                    images_path:
                                        "/static/images/palm_jebel_ali/ukl/",
                                    images_number: 10,
                                },
                                {
                                    title: "palm_jebel_ali_mnop",
                                    images_path:
                                        "/static/images/palm_jebel_ali/mnop/",
                                    images_number: 3,
                                },
                            ],
                        },
                        {
                            type: "img",
                            text: "card_masterplan",
                            data: [
                                {
                                    images_path:
                                        "/static/images/palm_jebel_ali/masterplan/",
                                    images_number: 2,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    },
    {
        id: "palm_jumeirah",
        checkIsInGroup: true,
        groupName: ["nakheel", "apartments"],
        zoomLevel: null,
        coords: { lat: 25.1223564585631, lng: 55.1319115423103 },
        polygonCoords: [palm_jumeirah_1, palm_jumeirah_2],
        icon: {
            label: "palm_jumeirah",
            bgColor: BLUE_BG_COLOR,
            textColor: WHITE_TEXT_COLOR,
            align: "top",
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "veneto",
        checkIsInGroup: true,
        groupName: ["nakheel", "plots"],
        zoomLevel: null,
        coords: { lat: 24.9548784129207, lng: 54.9978584756706 },
        polygonCoords: null,
        icon: {
            label: "veneto",
            bgColor: BLUE_BG_COLOR,
            textColor: WHITE_TEXT_COLOR,
            align: "left",
        },
        activeIcon: MarkVenetoBlack,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "dubai_islands",
        checkIsInGroup: true,
        groupName: ["nakheel", "plots_villas_apartments"],
        zoomLevel: null,
        coords: { lat: 25.3208847325933, lng: 55.3034074496439 },
        polygonCoords: [dubai_islands],
        icon: {
            label: "dubai_islands",
            bgColor: BLUE_BG_COLOR,
            textColor: WHITE_TEXT_COLOR,
            align: "right",
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            title: "dubai_islands",
            previews: [DubaiIslandsPreview1, DubaiIslandsPreview2],
            desc: "dubai_islands_desc",
            link: "https://www.canalfrontresidences.meydan.ae/",
            video: DubaiIslandsVideo,
            buttons: [
                {
                    type: "double",
                    data: [
                        {
                            type: "img",
                            text: "card_brochure",
                            data: [
                                {
                                    images_path:
                                        "/static/images/dubai_islands/brochure/",
                                    images_number: 26,
                                },
                            ],
                        },
                        {
                            type: "img",
                            text: "dubai_islands_island_a",
                            data: [
                                {
                                    images_path:
                                        "/static/images/dubai_islands/island_a/",
                                    images_number: 2,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    },
    {
        id: "jebel_ali_village",
        checkIsInGroup: true,
        groupName: ["nakheel", "villas"],
        zoomLevel: null,
        coords: { lat: 25.0298263289557, lng: 55.1313941679255 },
        polygonCoords: [],
        icon: {
            label: "jebel_ali_village",
            bgColor: BLUE_BG_COLOR,
            textColor: WHITE_TEXT_COLOR,
            align: "top",
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            title: "jebel_ali_village",
            previews: [
                "/static/images/jebel_ali_village/previews/1.jpg",
                "/static/images/jebel_ali_village/previews/2.jpg",
                "/static/images/jebel_ali_village/previews/3.jpg",
                "/static/images/jebel_ali_village/previews/4.jpg",
                "/static/images/jebel_ali_village/previews/5.jpg",
                "/static/images/jebel_ali_village/previews/6.jpg",
                "/static/images/jebel_ali_village/previews/7.jpg",
                "/static/images/jebel_ali_village/previews/8.jpg",
                "/static/images/jebel_ali_village/previews/9.jpg",
            ],
            desc: "jebel_ali_village_desc",
            video: JebelAliVillageVideo,
            buttons: [
                {
                    type: "double",
                    data: [
                        {
                            type: "img",
                            text: "card_brochure",
                            data: [
                                {
                                    images_path:
                                        "/static/images/jebel_ali_village/brochure/",
                                    images_number: 35,
                                },
                            ],
                        },
                        {
                            type: "img",
                            text: "card_fact_sheet",
                            data: [
                                {
                                    images_path:
                                        "/static/images/jebel_ali_village/fact_sheet/",
                                    images_number: 2,
                                },
                            ],
                        },
                    ],
                },
                {
                    type: "double",
                    data: [
                        {
                            type: "img",
                            text: "card_floor_plans",
                            data: [
                                {
                                    images_path:
                                        "/static/images/jebel_ali_village/floor_plans/",
                                    images_number: 19,
                                },
                            ],
                        },
                        {
                            type: "img",
                            text: "jebel_ali_village_masterplan_a3",
                            data: [
                                {
                                    images_path:
                                        "/static/images/jebel_ali_village/masterplan_a3/",
                                    images_number: 1,
                                },
                            ],
                        },
                    ],
                },
                {
                    type: "single",
                    data: [
                        {
                            type: "img",
                            text: "card_payment_plan",
                            data: [
                                {
                                    images_path:
                                        "/static/images/jebel_ali_village/payment_plan/",
                                    images_number: 1,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    },
    {
        id: "jebel_ali_club",
        checkIsInGroup: true,
        groupName: ["nakheel", "plots"],
        zoomLevel: null,
        coords: { lat: 25.0359815063039, lng: 55.115931997769 },
        polygonCoords: [],
        icon: {
            label: "jebel_ali_club",
            bgColor: BLUE_BG_COLOR,
            textColor: WHITE_TEXT_COLOR,
            align: "left",
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "jumeirah_park",
        checkIsInGroup: true,
        groupName: ["nakheel", "plots"],
        zoomLevel: null,
        coords: { lat: 25.0469980610292, lng: 55.1552081007093 },
        polygonCoords: [],
        icon: {
            label: "jumeirah_park",
            bgColor: BLUE_BG_COLOR,
            textColor: WHITE_TEXT_COLOR,
            align: "bottom",
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "jumeirah_heights",
        checkIsInGroup: true,
        groupName: ["nakheel", "plots"],
        zoomLevel: null,
        coords: { lat: 25.0667078013068, lng: 55.147858750232 },
        polygonCoords: [jumeirah_heights],
        icon: {
            label: "jumeirah_heights",
            bgColor: BLUE_BG_COLOR,
            textColor: WHITE_TEXT_COLOR,
            align: "right",
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "jumeirah_islands",
        checkIsInGroup: true,
        groupName: ["nakheel", "villas"],
        zoomLevel: null,
        coords: { lat: 25.0576104830134, lng: 55.1557827842716 },
        polygonCoords: [],
        icon: {
            label: "jumeirah_islands",
            bgColor: BLUE_BG_COLOR,
            textColor: WHITE_TEXT_COLOR,
            align: "right",
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "al_furjan",
        checkIsInGroup: true,
        groupName: ["nakheel", "villas", "new_project"],
        zoomLevel: null,
        coords: { lat: 25.0251434071843, lng: 55.1471077931815 },
        polygonCoords: [],
        icon: {
            label: "al_furjan",
            bgColor: BLUE_BG_COLOR,
            textColor: WHITE_TEXT_COLOR,
            align: "left",
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "murooj_al_furjan",
        checkIsInGroup: true,
        groupName: ["nakheel", "villas"],
        zoomLevel: null,
        coords: { lat: 25.0303480632162, lng: 55.1384807977714 },
        polygonCoords: [],
        icon: {
            label: "murooj_al_furjan",
            bgColor: BLUE_BG_COLOR,
            textColor: WHITE_TEXT_COLOR,
            align: "right",
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "tilal_al_furjan",
        checkIsInGroup: true,
        groupName: ["nakheel", "villas"],
        zoomLevel: null,
        coords: { lat: 25.0263591519061, lng: 55.1304321969928 },
        polygonCoords: [],
        icon: {
            label: "tilal_al_furjan",
            bgColor: BLUE_BG_COLOR,
            textColor: WHITE_TEXT_COLOR,
            align: "left",
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            title: "tilal_al_furjan",
            previews: [
                "/static/images/palm_jebel_ali/previews/1.jpg",
                "/static/images/palm_jebel_ali/previews/2.jpg",
                "/static/images/palm_jebel_ali/previews/3.jpg",
                "/static/images/palm_jebel_ali/previews/4.jpg",
                "/static/images/palm_jebel_ali/previews/5.jpg",
            ],
            desc: "tilal_al_furjan_desc",
            link: "https://www.tilalalfurjan.ae/",
            video: TilalAlFurjanVideo,
            buttons: [
                {
                    type: "single",
                    data: [
                        {
                            type: "img",
                            text: "card_floor_plans_and_brochure",
                            data: [
                                {
                                    images_path:
                                        "/static/images/tilal_al_furjan/floor_plans_and_brochure/",
                                    images_number: 41,
                                },
                            ],
                        },
                    ],
                },
                {
                    type: "single",
                    data: [
                        {
                            type: "img",
                            text: "card_fact_sheet",
                            data: [
                                {
                                    images_path:
                                        "/static/images/tilal_al_furjan/fact_sheet/",
                                    images_number: 3,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    },
    {
        id: "lagoon_views",
        checkIsInGroup: true,
        groupName: ["nakheel", "apartments"],
        zoomLevel: null,
        coords: { lat: 25.16031045557504, lng: 55.28062817116014 },
        polygonCoords: [],
        icon: {
            label: "lagoon_views",
            bgColor: BLUE_BG_COLOR,
            textColor: WHITE_TEXT_COLOR,
            align: "left",
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            title: "lagoon_views",
            previews: [
                "/static/images/lagoon_views/previews/1.jpg",
                "/static/images/lagoon_views/previews/2.jpg",
                "/static/images/lagoon_views/previews/3.jpg",
                "/static/images/lagoon_views/previews/4.jpg",
                "/static/images/lagoon_views/previews/5.jpg",
                "/static/images/lagoon_views/previews/6.jpg",
            ],
            desc: "lagoon_views_desc",
            link: null,
            video: LagoonViewsVideo,
            buttons: [
                {
                    type: "double",
                    data: [
                        {
                            type: "img",
                            text: "card_masterplan",
                            data: [
                                {
                                    images_path:
                                        "/static/images/lagoon_views/masterplan/",
                                    images_number: 1,
                                },
                            ],
                        },
                        {
                            type: "img",
                            text: "card_payment_plan",
                            data: [
                                {
                                    images_path:
                                        "/static/images/lagoon_views/payment_plan/",
                                    images_number: 1,
                                },
                            ],
                        },
                    ],
                },
                {
                    type: "double",
                    data: [
                        {
                            type: "img",
                            text: "card_floor_plans",
                            data: [
                                {
                                    images_path:
                                        "/static/images/lagoon_views/floorplans/",
                                    images_number: 32,
                                },
                            ],
                        },
                        {
                            type: "img",
                            text: "card_brochure",
                            data: [
                                {
                                    images_path:
                                        "/static/images/lagoon_views/brochure/",
                                    images_number: 34,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    },
    {
        id: "masakin_al_furjan",
        checkIsInGroup: true,
        groupName: ["nakheel", "villas"],
        zoomLevel: null,
        coords: { lat: 25.0303178662483, lng: 55.1492385030902 },
        polygonCoords: [],
        icon: {
            label: "masakin_al_furjan",
            bgColor: BLUE_BG_COLOR,
            textColor: WHITE_TEXT_COLOR,
            align: "bottom",
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "discovery_gardens",
        checkIsInGroup: true,
        groupName: ["nakheel", "villas"],
        zoomLevel: null,
        coords: { lat: 25.039989855318, lng: 55.1440769023553 },
        polygonCoords: [],
        icon: {
            label: "discovery_gardens",
            bgColor: BLUE_BG_COLOR,
            textColor: WHITE_TEXT_COLOR,
            align: "right",
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "jumeirah_village_triangle",
        checkIsInGroup: true,
        groupName: ["nakheel", "villas"],
        zoomLevel: null,
        coords: { lat: 25.0485210715624, lng: 55.1898620162536 },
        polygonCoords: [jumeirah_village_triangle],
        icon: {
            label: "jumeirah_village_triangle",
            bgColor: BLUE_BG_COLOR,
            textColor: WHITE_TEXT_COLOR,
            align: "right",
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "jumeirah_village_circle",
        checkIsInGroup: true,
        groupName: ["nakheel", "villas"],
        zoomLevel: null,
        coords: { lat: 25.0612529333819, lng: 55.2096228990439 },
        polygonCoords: [jumeirah_village_circle],
        icon: {
            label: "jumeirah_village_circle",
            bgColor: BLUE_BG_COLOR,
            textColor: WHITE_TEXT_COLOR,
            align: "bottom",
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "como_residences",
        checkIsInGroup: true,
        groupName: ["nakheel", "apartments"],
        zoomLevel: null,
        coords: { lat: 25.1110401298578, lng: 55.1458511956965 },
        polygonCoords: [],
        icon: {
            label: "como_residences",
            bgColor: BLUE_BG_COLOR,
            textColor: WHITE_TEXT_COLOR,
            align: "left",
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            title: "como_residences",
            previews: [
                "/static/images/como_residences/previews/1.jpg",
                "/static/images/como_residences/previews/2.jpg",
                "/static/images/como_residences/previews/3.jpg",
                "/static/images/como_residences/previews/4.jpg",
                "/static/images/como_residences/previews/5.jpg",
                "/static/images/como_residences/previews/6.jpg",
                "/static/images/como_residences/previews/7.jpg",
                "/static/images/como_residences/previews/8.jpg",
            ],
            desc: "como_residences_desc",
            video: VideoComoResidences,
            link: "https://www.comobynakheel.com/",
            translations: true,
            buttons: {
                en: [
                    {
                        type: "double",
                        data: [
                            {
                                type: "img",
                                text: "card_payment_plan",
                                data: [
                                    {
                                        images_path:
                                            "/static/images/como_residences/en/payment_plan/",
                                        images_number: 1,
                                    },
                                ],
                            },
                            {
                                type: "img",
                                text: "card_floor_plans",
                                data: [
                                    {
                                        images_path:
                                            "/static/images/como_residences/en/floorplans/",
                                        images_number: 81,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        type: "single",
                        data: [
                            {
                                type: "img",
                                text: "card_mat_board",
                                data: [
                                    {
                                        images_path:
                                            "/static/images/como_residences/en/mat_board/",
                                        images_number: 14,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                zh: [
                    {
                        type: "double",
                        data: [
                            {
                                type: "img",
                                text: "card_brochure",
                                data: [
                                    {
                                        images_path:
                                            "/static/images/como_residences/zh/brochure/",
                                        images_number: 86,
                                    },
                                ],
                            },
                            {
                                type: "img",
                                text: "card_floor_plans",
                                data: [
                                    {
                                        images_path:
                                            "/static/images/como_residences/zh/floorplans/",
                                        images_number: 79,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                pt: [
                    {
                        type: "single",
                        data: [
                            {
                                type: "img",
                                text: "card_brochure",
                                data: [
                                    {
                                        images_path:
                                            "/static/images/como_residences/pt/brochure/",
                                        images_number: 86,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
    },
    {
        id: "greenwood",
        checkIsInGroup: true,
        groupName: ["nakheel", "plots"],
        zoomLevel: null,
        coords: { lat: 25.14225, lng: 55.406722 },
        polygonCoords: [],
        icon: {
            label: "greenwood",
            bgColor: BLUE_BG_COLOR,
            textColor: WHITE_TEXT_COLOR,
            align: "left",
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            title: "greenwood",
            previews: [
                "/static/images/greenwood/previews/1.jpg",
                "/static/images/greenwood/previews/2.jpg",
                "/static/images/greenwood/previews/3.jpg",
                "/static/images/greenwood/previews/4.jpg",
            ],
            desc: "greenwood_desc",
            video: GreenwoodVideo,
            translations: true,
            buttons: {
                en: [
                    {
                        type: "double",
                        data: [
                            {
                                type: "img",
                                text: "card_brochure",
                                data: [
                                    {
                                        images_path:
                                            "/static/images/greenwood/en/brochure/",
                                        images_number: 30,
                                    },
                                ],
                            },
                            {
                                type: "img",
                                text: "card_masterplan",
                                data: [
                                    {
                                        images_path:
                                            "/static/images/greenwood/en/masterplan/",
                                        images_number: 1,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                zh: [
                    {
                        type: "single",
                        data: [
                            {
                                type: "img",
                                text: "card_brochure",
                                data: [
                                    {
                                        images_path:
                                            "/static/images/greenwood/zh/brochure/",
                                        images_number: 30,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
    },
    {
        id: "rixos",
        checkIsInGroup: true,
        groupName: ["nakheel", "apartments_villas"],
        zoomLevel: null,
        coords: { lat: 25.30139936, lng: 55.31203838 },
        polygonCoords: [],
        icon: {
            label: "rixos",
            bgColor: BLUE_BG_COLOR,
            textColor: WHITE_TEXT_COLOR,
            align: "right",
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            title: "rixos",
            previews: [
                "/static/images/rixos/previews/1.jpg",
                "/static/images/rixos/previews/2.jpg",
                "/static/images/rixos/previews/3.jpg",
                "/static/images/rixos/previews/4.jpg",
                "/static/images/rixos/previews/5.jpg",
            ],
            desc: "rixos_desc",
            link: "https://rixos-landing.webflow.io/emotions/main-page",
            video: RixosVideo,
            translations: true,
            buttons: {
                en: [
                    {
                        type: "double",
                        data: [
                            {
                                type: "img",
                                text: "card_brochure",
                                data: [
                                    {
                                        images_path:
                                            "/static/images/rixos/en/brochure/",
                                        images_number: 50,
                                    },
                                ],
                            },
                            {
                                type: "img",
                                text: "card_floor_plans",
                                data: [
                                    {
                                        title: "rixos_a1",
                                        images_path:
                                            "/static/images/rixos/en/floor_plans/a1/",
                                        images_number: 67,
                                    },
                                    {
                                        title: "rixos_a2",
                                        images_path:
                                            "/static/images/rixos/en/floor_plans/a2/",
                                        images_number: 66,
                                    },
                                    {
                                        title: "rixos_a3",
                                        images_path:
                                            "/static/images/rixos/en/floor_plans/a3/",
                                        images_number: 103,
                                    },
                                    {
                                        title: "rixos_a4",
                                        images_path:
                                            "/static/images/rixos/en/floor_plans/a4/",
                                        images_number: 62,
                                    },
                                    {
                                        title: "rixos_beach_houses",
                                        images_path:
                                            "/static/images/rixos/en/floor_plans/beach_houses/",
                                        images_number: 20,
                                    },
                                    {
                                        title: "rixos_villas",
                                        images_path:
                                            "/static/images/rixos/en/floor_plans/villas/",
                                        images_number: 20,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        type: "double",
                        data: [
                            {
                                type: "img",
                                text: "card_fact_sheet",
                                data: [
                                    {
                                        images_path:
                                            "/static/images/rixos/en/fact_sheet/",
                                        images_number: 25,
                                    },
                                ],
                            },
                            {
                                type: "img",
                                text: "card_payment_plan",
                                data: [
                                    {
                                        images_path:
                                            "/static/images/rixos/en/payment_plan/",
                                        images_number: 1,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                zh: [
                    {
                        type: "double",
                        data: [
                            {
                                type: "img",
                                text: "card_brochure",
                                data: [
                                    {
                                        images_path:
                                            "/static/images/rixos/zh/brochure/",
                                        images_number: 39,
                                    },
                                ],
                            },
                            {
                                type: "img",
                                text: "card_floor_plans",
                                data: [
                                    {
                                        title: "rixos_a1",
                                        images_path:
                                            "/static/images/rixos/zh/floor_plans/p2/",
                                        images_number: 47,
                                    },
                                    {
                                        title: "rixos_a2",
                                        images_path:
                                            "/static/images/rixos/zh/floor_plans/b2/",
                                        images_number: 94,
                                    },
                                    {
                                        title: "rixos_a3",
                                        images_path:
                                            "/static/images/rixos/zh/floor_plans/b3/",
                                        images_number: 95,
                                    },
                                    {
                                        title: "rixos_a4",
                                        images_path:
                                            "/static/images/rixos/zh/floor_plans/b4/",
                                        images_number: 60,
                                    },
                                    {
                                        title: "rixos_beach_houses",
                                        images_path:
                                            "/static/images/rixos/zh/floor_plans/beach_houses/",
                                        images_number: 23,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
    },
    {
        id: "district_one_west",
        checkIsInGroup: true,
        groupName: ["nakheel", "villas"],
        zoomLevel: null,
        coords: { lat: 25.14489796, lng: 55.27764592 },
        polygonCoords: [],
        icon: {
            label: "district_one_west",
            bgColor: BLUE_BG_COLOR,
            textColor: WHITE_TEXT_COLOR,
            align: "left",
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            title: "district_one_west",
            previews: [
                "/static/images/district_one_west/previews/1.jpg",
                "/static/images/district_one_west/previews/2.jpg",
                "/static/images/district_one_west/previews/3.jpg",
                "/static/images/district_one_west/previews/4.jpg",
                "/static/images/district_one_west/previews/5.jpg",
                "/static/images/district_one_west/previews/6.jpg",
                "/static/images/district_one_west/previews/7.jpg",
                "/static/images/district_one_west/previews/8.jpg",
                "/static/images/district_one_west/previews/9.jpg",
                "/static/images/district_one_west/previews/10.jpg",
                "/static/images/district_one_west/previews/11.jpg",
                "/static/images/district_one_west/previews/12.jpg",
            ],
            desc: "district_one_west_desc",
            video: DistrictOneVideo,
            buttons: [
                {
                    type: "double",
                    data: [
                        {
                            type: "img",
                            text: "card_brochure",
                            data: [
                                {
                                    images_path:
                                        "/static/images/district_one_west/brochure/",
                                    images_number: 55,
                                },
                            ],
                        },
                        {
                            type: "img",
                            text: "card_floor_plans",
                            data: [
                                {
                                    images_path:
                                        "/static/images/district_one_west/floor_plans/",
                                    images_number: 43,
                                },
                            ],
                        },
                    ],
                },
                {
                    type: "single",
                    data: [
                        {
                            type: "img",
                            text: "card_masterplan",
                            data: [
                                {
                                    images_path:
                                        "/static/images/district_one_west/masterplan/",
                                    images_number: 1,
                                },
                            ],
                        },
                    ],
                },
                {
                    type: "single",
                    data: [
                        {
                            type: "img",
                            text: "card_mat_board_brochure",
                            data: [
                                {
                                    images_path:
                                        "/static/images/district_one_west/mat_board_brochure/",
                                    images_number: 12,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    },
    {
        id: "the_palm_tower_residences",
        checkIsInGroup: true,
        groupName: ["nakheel", "apartments"],
        zoomLevel: null,
        coords: { lat: 25.1149504481807, lng: 55.13787513591 },
        polygonCoords: [],
        icon: {
            label: "the_palm_tower_residences",
            bgColor: BLUE_BG_COLOR,
            textColor: WHITE_TEXT_COLOR,
            align: "top",
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "st_regis_dubai",
        checkIsInGroup: true,
        groupName: ["nakheel", "apartments"],
        zoomLevel: null,
        coords: { lat: 25.1131661007271, lng: 55.1418165472705 },
        polygonCoords: [],
        icon: {
            label: "st_regis_dubai",
            bgColor: BLUE_BG_COLOR,
            textColor: WHITE_TEXT_COLOR,
            align: "right",
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "palm_beach_towers",
        checkIsInGroup: true,
        groupName: ["nakheel", "apartments"],
        zoomLevel: null,
        coords: { lat: 25.1017416800526, lng: 55.1549168967729 },
        polygonCoords: [],
        icon: {
            label: "palm_beach_towers",
            bgColor: BLUE_BG_COLOR,
            textColor: WHITE_TEXT_COLOR,
            align: "left",
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            title: "palm_beach_towers",
            previews: [
                "/static/images/palm_beach_towers/previews/1.jpg",
                "/static/images/palm_beach_towers/previews/5.jpg",
                "/static/images/palm_beach_towers/previews/6.jpg",
                "/static/images/palm_beach_towers/previews/8.jpg",
                "/static/images/palm_beach_towers/previews/9.jpg",
                "/static/images/palm_beach_towers/previews/10.jpg",
                "/static/images/palm_beach_towers/previews/11.jpg",
            ],
            desc: "palm_beach_towers_desc",
            link: "https://www.palmbeachtowers.ae",
            video: PalmBeachTowersVideo,
            translations: true,
            buttons: {
                en: [
                    {
                        type: "single",
                        data: [
                            {
                                type: "img",
                                text: "card_floor_plans",
                                data: [
                                    {
                                        title: "palm_beach_towers_tower_1",
                                        images_path:
                                            "/static/images/palm_beach_towers/en/floor_plans/tower_1/",
                                        images_number: 96,
                                    },
                                    {
                                        title: "palm_beach_towers_tower_2",
                                        images_path:
                                            "/static/images/palm_beach_towers/en/floor_plans/tower_2/",
                                        images_number: 77,
                                    },
                                    {
                                        title: "palm_beach_towers_tower_3",
                                        images_path:
                                            "/static/images/palm_beach_towers/en/floor_plans/tower_3/",
                                        images_number: 57,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                zh: [
                    {
                        type: "single",
                        data: [
                            {
                                type: "img",
                                text: "card_brochure",
                                data: [
                                    {
                                        images_path:
                                            "/static/images/palm_beach_towers/zh/brochure/",
                                        images_number: 18,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                pt: [
                    {
                        type: "single",
                        data: [
                            {
                                type: "img",
                                text: "card_brochure",
                                data: [
                                    {
                                        images_path:
                                            "/static/images/palm_beach_towers/pt/brochure/",
                                        images_number: 18,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
    },
    {
        id: "the_world",
        checkIsInGroup: true,
        groupName: ["nakheel", "apartments"],
        zoomLevel: null,
        coords: { lat: 25.2235063463483, lng: 55.1696737430049 },
        polygonCoords: [
            the_world_pol_1,
            the_world_pol_2,
            the_world_pol_3,
            the_world_pol_4,
            the_world_pol_5,
            the_world_pol_6,
            the_world_pol_7,
            the_world_pol_8,
            the_world_pol_9,
            the_world_pol_10,
            the_world_pol_11,
            the_world_pol_12,
            the_world_pol_13,
            the_world_pol_14,
            the_world_pol_15,
            the_world_pol_16,
            the_world_pol_17,
            the_world_pol_18,
            the_world_pol_19,
            the_world_pol_20,
            the_world_pol_21,
            the_world_pol_22,
            the_world_pol_23,
            the_world_pol_24,
            the_world_pol_25,
            the_world_pol_26,
            the_world_pol_27,
            the_world_pol_28,
            the_world_pol_29,
            the_world_pol_30,
            the_world_pol_31,
            the_world_pol_32,
            the_world_pol_33,
            the_world_pol_34,
            the_world_pol_35,
            the_world_pol_36,
            the_world_pol_37,
            the_world_pol_38,
            the_world_pol_39,
            the_world_pol_40,
            the_world_pol_41,
            the_world_pol_42,
            the_world_pol_43,
            the_world_pol_44,
            the_world_pol_45,
            the_world_pol_46,
            the_world_pol_47,
            the_world_pol_48,
            the_world_pol_49,
            the_world_pol_50,
            the_world_pol_51,
            the_world_pol_52,
            the_world_pol_53,
            the_world_pol_54,
            the_world_pol_55,
            the_world_pol_56,
            the_world_pol_57,
            the_world_pol_58,
            the_world_pol_59,
            the_world_pol_60,
            the_world_pol_61,
            the_world_pol_62,
            the_world_pol_63,
            the_world_pol_64,
            the_world_pol_65,
            the_world_pol_66,
            the_world_pol_67,
            the_world_pol_68,
            the_world_pol_69,
            the_world_pol_70,
            the_world_pol_71,
            the_world_pol_72,
            the_world_pol_73,
            the_world_pol_74,
            the_world_pol_75,
            the_world_pol_76,
            the_world_pol_77,
            the_world_pol_78,
            the_world_pol_79,
            the_world_pol_80,
            the_world_pol_81,
            the_world_pol_82,
            the_world_pol_83,
            the_world_pol_84,
            the_world_pol_85,
            the_world_pol_86,
            the_world_pol_87,
            the_world_pol_88,
            the_world_pol_89,
            the_world_pol_90,
            the_world_pol_91,
            the_world_pol_92,
            the_world_pol_93,
            the_world_pol_94,
            the_world_pol_95,
            the_world_pol_96,
            the_world_pol_97,
            the_world_pol_98,
            the_world_pol_99,
            the_world_pol_100,
            the_world_pol_101,
            the_world_pol_102,
            the_world_pol_103,
            the_world_pol_104,
            the_world_pol_105,
            the_world_pol_106,
            the_world_pol_107,
            the_world_pol_108,
            the_world_pol_109,
            the_world_pol_110,
            the_world_pol_111,
            the_world_pol_112,
            the_world_pol_113,
            the_world_pol_114,
            the_world_pol_115,
            the_world_pol_116,
            the_world_pol_117,
            the_world_pol_118,
            the_world_pol_119,
            the_world_pol_120,
            the_world_pol_121,
            the_world_pol_122,
            the_world_pol_123,
            the_world_pol_124,
            the_world_pol_125,
            the_world_pol_126,
            the_world_pol_127,
            the_world_pol_128,
            the_world_pol_129,
            the_world_pol_130,
            the_world_pol_131,
            the_world_pol_132,
            the_world_pol_133,
            the_world_pol_134,
            the_world_pol_135,
            the_world_pol_136,
            the_world_pol_137,
            the_world_pol_138,
            the_world_pol_139,
            the_world_pol_140,
            the_world_pol_141,
            the_world_pol_142,
            the_world_pol_143,
            the_world_pol_144,
            the_world_pol_145,
            the_world_pol_146,
            the_world_pol_147,
            the_world_pol_148,
            the_world_pol_149,
            the_world_pol_150,
            the_world_pol_151,
            the_world_pol_152,
            the_world_pol_153,
            the_world_pol_154,
            the_world_pol_155,
            the_world_pol_156,
            the_world_pol_157,
            the_world_pol_158,
            the_world_pol_159,
            the_world_pol_160,
            the_world_pol_161,
            the_world_pol_162,
            the_world_pol_163,
            the_world_pol_164,
            the_world_pol_165,
            the_world_pol_166,
            the_world_pol_167,
            the_world_pol_168,
            the_world_pol_169,
            the_world_pol_170,
            the_world_pol_171,
            the_world_pol_172,
            the_world_pol_173,
            the_world_pol_174,
            the_world_pol_175,
            the_world_pol_176,
            the_world_pol_177,
            the_world_pol_178,
            the_world_pol_179,
            the_world_pol_180,
            the_world_pol_181,
            the_world_pol_182,
            the_world_pol_183,
            the_world_pol_184,
            the_world_pol_185,
            the_world_pol_186,
            the_world_pol_187,
            the_world_pol_188,
            the_world_pol_189,
            the_world_pol_190,
            the_world_pol_191,
            the_world_pol_192,
            the_world_pol_193,
            the_world_pol_194,
            the_world_pol_195,
            the_world_pol_196,
            the_world_pol_197,
            the_world_pol_198,
            the_world_pol_199,
            the_world_pol_200,
            the_world_pol_201,
            the_world_pol_202,
            the_world_pol_203,
            the_world_pol_204,
        ],
        icon: {
            label: "the_world",
            bgColor: GREY_BG_COLOR,
            textColor: WHITE_TEXT_COLOR,
            align: "top",
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "nad_al_sheba_villas",
        checkIsInGroup: true,
        groupName: ["nakheel", "apartments"],
        zoomLevel: null,
        coords: { lat: 25.1601657220897, lng: 55.3718879480575 },
        polygonCoords: [],
        icon: {
            label: "nad_al_sheba_villas",
            bgColor: BLUE_BG_COLOR,
            textColor: WHITE_TEXT_COLOR,
            align: "right",
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "warsan_village",
        checkIsInGroup: true,
        groupName: ["nakheel", "apartments"],
        zoomLevel: null,
        coords: { lat: 25.1555223698421, lng: 55.4050612441932 },
        polygonCoords: [],
        icon: {
            label: "warsan_village",
            bgColor: BLUE_BG_COLOR,
            textColor: WHITE_TEXT_COLOR,
            align: "left",
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "international_city",
        checkIsInGroup: true,
        groupName: ["nakheel", "apartments"],
        zoomLevel: null,
        coords: { lat: 25.1647181646089, lng: 55.4080603547548 },
        polygonCoords: [international_city_1, international_city_2],
        icon: {
            label: "international_city",
            bgColor: BLUE_BG_COLOR,
            textColor: WHITE_TEXT_COLOR,
            align: "right",
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "canal_front_residences",
        checkIsInGroup: true,
        groupName: ["meydan", "apartments"],
        zoomLevel: null,
        coords: { lat: 25.185830988435, lng: 55.248199985770604 },
        polygonCoords: [canal_front_residences],
        icon: {
            label: "canal_front_residences",
            bgColor: GREY_BG_COLOR,
            textColor: WHITE_TEXT_COLOR,
            align: "right",
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            title: "canal_front_residences",
            previews: [
                "/static/images/canal_front_residences/previews/1.jpg",
                "/static/images/canal_front_residences/previews/2.jpg",
                "/static/images/canal_front_residences/previews/3.jpg",
                "/static/images/canal_front_residences/previews/4.jpg",
                "/static/images/canal_front_residences/previews/5.jpg",
            ],
            desc: "The Canal Front Residences by Meydan on the Dubai Water Canal mirrors the beguiling charm of its vicinity. A spectacular ensemble of one-, two- and three-bedroom apartments with scenic views offers the perfect setting to transform your lifestyle. The idyllic location has doorstep amenities, an eclectic mix of restaurants, an array of shopping destinations, lush green landscapes and ample options for recreation.",
            link: "https://www.canalfrontresidences.meydan.ae/",
            video: CanalFrontResidencesVideo,
            buttons: [
                {
                    type: "double",
                    data: [
                        {
                            type: "img",
                            text: "card_brochure",
                            data: [
                                {
                                    images_path:
                                        "/static/images/canal_front_residences/brochure/",
                                    images_number: 27,
                                },
                            ],
                        },
                        {
                            type: "img",
                            text: "card_floor_plans",
                            data: [
                                {
                                    images_path:
                                        "/static/images/canal_front_residences/floor_plans/",
                                    images_number: 59,
                                },
                            ],
                        },
                    ],
                },
                {
                    type: "double",
                    data: [
                        {
                            type: "img",
                            text: "card_fact_sheet",
                            data: [
                                {
                                    images_path:
                                        "/static/images/canal_front_residences/fact_sheet/",
                                    images_number: 5,
                                },
                            ],
                        },
                        {
                            type: "img",
                            text: "card_payment_plan",
                            data: [
                                {
                                    images_path:
                                        "/static/images/canal_front_residences/payment_plan/",
                                    images_number: 1,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    },
    {
        id: "district_one",
        checkIsInGroup: true,
        groupName: ["meydan", "plots"],
        zoomLevel: null,
        coords: { lat: 25.1664659277986, lng: 55.2772011690859 },
        polygonCoords: [district_one],
        icon: {
            label: "district_one",
            bgColor: GREY_BG_COLOR,
            textColor: WHITE_TEXT_COLOR,
            align: "right",
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    // {
    //     id: 'district_one_west1',
    //     checkIsInGroup: true,
    //     groupName: ['meydan', 'plots'],
    //     zoomLevel: 12,
    //     coords: {lat: 25.1452064907647, lng: 55.2776460317349},
    //     polygonCoords: null,
    //     icon: MarkDistrictOneWest,
    //     activeIcon: null,
    //     clickable: {
    //         marker: true,
    //         polygon: true
    //     },
    //     content: null
    // },
    {
        id: "golf_course",
        checkIsInGroup: true,
        groupName: ["meydan", "villas"],
        zoomLevel: null,
        coords: { lat: 25.160213527711, lng: 55.3154315968028 },
        polygonCoords: null,
        icon: {
            label: "golf_course",
            bgColor: GREY_BG_COLOR,
            textColor: WHITE_TEXT_COLOR,
            align: "bottom",
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "polo_residence",
        checkIsInGroup: true,
        groupName: ["meydan", "villas"],
        zoomLevel: null,
        coords: { lat: 25.1497592544695, lng: 55.2948257942159 },
        polygonCoords: null,
        icon: {
            label: "polo_residence",
            bgColor: GREY_BG_COLOR,
            textColor: WHITE_TEXT_COLOR,
            align: "left",
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "meydan_hotel",
        checkIsInGroup: true,
        groupName: ["meydan", "villas"],
        zoomLevel: null,
        coords: { lat: 25.1586989530687, lng: 55.3065742049431 },
        polygonCoords: null,
        icon: {
            label: "meydan_hotel",
            bgColor: GREY_BG_COLOR,
            textColor: WHITE_TEXT_COLOR,
            align: "right",
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "meydan_avenue",
        checkIsInGroup: true,
        groupName: ["meydan", "plots"],
        zoomLevel: null,
        coords: { lat: 25.1578386088726, lng: 55.2945025159534 },
        polygonCoords: null,
        icon: {
            label: "meydan_avenue",
            bgColor: GREY_BG_COLOR,
            textColor: WHITE_TEXT_COLOR,
            align: "right",
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            title: "meydan_avenue",
            previews: [
                "/static/images/meydan_avenue/previews/1.jpg",
                "/static/images/meydan_avenue/previews/2.jpg",
                "/static/images/meydan_avenue/previews/3.jpg",
                "/static/images/meydan_avenue/previews/4.jpg",
                "/static/images/meydan_avenue/previews/5.jpg",
                "/static/images/meydan_avenue/previews/6.jpg",
            ],
            desc: "meydan_avenue_desc",
            link: null,
            video: null,
            buttons: [
                {
                    type: "single",
                    data: [
                        {
                            type: "img",
                            text: "meydan_avenue_plot_a",
                            data: [
                                {
                                    title: "meydan_avenue_plot_design",
                                    images_path:
                                        "/static/images/meydan_avenue/plot_a/plot_design/",
                                    images_number: 54,
                                },
                                {
                                    title: "meydan_avenue_plot_dev_control",
                                    images_path:
                                        "/static/images/meydan_avenue/plot_a/dev_control/",
                                    images_number: 32,
                                },
                                {
                                    title: "meydan_avenue_plot_brochure",
                                    images_path:
                                        "/static/images/meydan_avenue/plot_a/brochure/",
                                    images_number: 23,
                                },
                            ],
                        },
                    ],
                },
                {
                    type: "single",
                    data: [
                        {
                            type: "img",
                            text: "meydan_avenue_plot_b",
                            data: [
                                {
                                    title: "meydan_avenue_plot_design",
                                    images_path:
                                        "/static/images/meydan_avenue/plot_b/plot_design/",
                                    images_number: 56,
                                },
                                {
                                    title: "meydan_avenue_plot_dev_control",
                                    images_path:
                                        "/static/images/meydan_avenue/plot_b/dev_control/",
                                    images_number: 17,
                                },
                                {
                                    title: "meydan_avenue_plot_brochure",
                                    images_path:
                                        "/static/images/meydan_avenue/plot_b/brochure/",
                                    images_number: 28,
                                },
                            ],
                        },
                    ],
                },
                {
                    type: "single",
                    data: [
                        {
                            type: "img",
                            text: "meydan_avenue_plot_c",
                            data: [
                                {
                                    title: "meydan_avenue_plot_design",
                                    images_path:
                                        "/static/images/meydan_avenue/plot_c/plot_design/",
                                    images_number: 47,
                                },
                                {
                                    title: "meydan_avenue_plot_dev_control",
                                    images_path:
                                        "/static/images/meydan_avenue/plot_c/dev_control/",
                                    images_number: 25,
                                },
                                {
                                    title: "meydan_avenue_plot_brochure",
                                    images_path:
                                        "/static/images/meydan_avenue/plot_c/brochure/",
                                    images_number: 33,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    },
    {
        id: "district_11",
        checkIsInGroup: true,
        groupName: ["meydan", "villas"],
        zoomLevel: null,
        coords: { lat: 25.125008726617, lng: 55.3520318687308 },
        polygonCoords: [district_11],
        icon: {
            label: "district_11",
            bgColor: GREY_BG_COLOR,
            textColor: WHITE_TEXT_COLOR,
            align: "right",
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            title: "district_11",
            previews: [
                "/static/images/district_11/previews/1.jpg",
                "/static/images/district_11/previews/2.jpg",
                "/static/images/district_11/previews/3.jpg",
                "/static/images/district_11/previews/4.jpg",
                "/static/images/district_11/previews/5.jpg",
                "/static/images/district_11/previews/6.jpg",
                "/static/images/district_11/previews/7.jpg",
                "/static/images/district_11/previews/8.jpg",
                "/static/images/district_11/previews/9.jpg",
                "/static/images/district_11/previews/10.jpg",
            ],
            desc: "district_11_desc",
            link: null,
            video: null,
            buttons: [
                {
                    type: "double",
                    data: [
                        {
                            type: "img",
                            text: "card_brochure",
                            data: [
                                {
                                    images_path:
                                        "/static/images/district_11/brochure/",
                                    images_number: 40,
                                },
                            ],
                        },
                        {
                            type: "img",
                            text: "card_floor_plans",
                            data: [
                                {
                                    images_path:
                                        "/static/images/district_11/floor_plans/",
                                    images_number: 11,
                                },
                            ],
                        },
                    ],
                },
                {
                    type: "single",
                    data: [
                        {
                            type: "img",
                            text: "card_fact_sheet",
                            data: [
                                {
                                    images_path:
                                        "/static/images/district_11/factsheet/",
                                    images_number: 4,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    },
    {
        id: "naya_residence",
        checkIsInGroup: true,
        groupName: ["meydan", "apartments"],
        zoomLevel: null,
        coords: { lat: 25.16281754, lng: 55.28744163 },
        polygonCoords: [],
        icon: {
            label: "naya_residence",
            bgColor: GREY_BG_COLOR,
            textColor: WHITE_TEXT_COLOR,
            align: "right",
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            title: "naya_residence",
            previews: [
                "/static/images/naya_residence/previews/1.jpg",
                "/static/images/naya_residence/previews/2.jpg",
                "/static/images/naya_residence/previews/3.jpg",
                "/static/images/naya_residence/previews/4.jpg",
                "/static/images/naya_residence/previews/5.jpg",
                "/static/images/naya_residence/previews/6.jpg",
                "/static/images/naya_residence/previews/7.jpg",
                "/static/images/naya_residence/previews/8.jpg",
                "/static/images/naya_residence/previews/9.jpg",
                "/static/images/naya_residence/previews/10.jpg",
            ],
            desc: "naya_residence_desc",
            link: "",
            video: NayaResidenceVideo,
            translations: true,
            buttons: {
                en: [
                    {
                        type: "double",
                        data: [
                            {
                                type: "img",
                                text: "card_floor_plans",
                                data: [
                                    {
                                        title: "naya_residence_1_floor",
                                        images_path:
                                            "/static/images/naya_residence/en/naya_1_floor/",
                                        images_number: 47,
                                    },
                                    {
                                        title: "naya_residence_2_floor",
                                        images_path:
                                            "/static/images/naya_residence/en/naya_2_floor/",
                                        images_number: 37,
                                    },
                                    {
                                        title: "naya_residence_3_floor",
                                        images_path:
                                            "/static/images/naya_residence/en/naya_3_floor/",
                                        images_number: 40,
                                    },
                                    {
                                        title: "naya_residence_villas",
                                        images_path:
                                            "/static/images/naya_residence/en/naya_villas/",
                                        images_number: 11,
                                    },
                                ],
                            },
                            {
                                type: "img",
                                text: "card_brochure",
                                data: [
                                    {
                                        images_path:
                                            "/static/images/naya_residence/en/brochure/",
                                        images_number: 34,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                zh: [
                    {
                        type: "double",
                        data: [
                            {
                                type: "img",
                                text: "card_floor_plans",
                                data: [
                                    {
                                        title: "naya_residence_1_floor",
                                        images_path:
                                            "/static/images/naya_residence/zh/naya_1_floor/",
                                        images_number: 47,
                                    },
                                    {
                                        title: "naya_residence_2_floor",
                                        images_path:
                                            "/static/images/naya_residence/zh/naya_2_floor/",
                                        images_number: 37,
                                    },
                                    {
                                        title: "naya_residence_3_floor",
                                        images_path:
                                            "/static/images/naya_residence/zh/naya_3_floor/",
                                        images_number: 40,
                                    },
                                    {
                                        title: "naya_residence_villas",
                                        images_path:
                                            "/static/images/naya_residence/zh/naya_villas/",
                                        images_number: 11,
                                    },
                                ],
                            },
                            {
                                type: "img",
                                text: "card_brochure",
                                data: [
                                    {
                                        images_path:
                                            "/static/images/naya_residence/zh/brochure/",
                                        images_number: 34,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                pt: [
                    {
                        type: "single",
                        data: [
                            {
                                type: "img",
                                text: "card_brochure",
                                data: [
                                    {
                                        images_path:
                                            "/static/images/naya_residence/pt/brochure/",
                                        images_number: 34,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
    },
    {
        id: "dxb_airport",
        checkIsInGroup: true,
        groupName: ["landmarks"],
        zoomLevel: null,
        coords: { lat: 25.253142380921556, lng: 55.3656575614154 },
        polygonCoords: null,
        icon: {
            label: "dxb_airport",
            bgColor: WHITE_BG_COLOR,
            textColor: BLACK_TEXT_COLOR,
            align: "bottom",
            icon: PlaneIcon,
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: false,
        },
        content: null,
    },
    {
        id: "dubai_canal",
        checkIsInGroup: true,
        groupName: ["landmarks"],
        zoomLevel: null,
        coords: { lat: 25.194975903997243, lng: 55.238651929645435 },
        polygonCoords: null,
        icon: {
            label: "dubai_canal",
            bgColor: WHITE_BG_COLOR,
            textColor: BLACK_TEXT_COLOR,
            align: "right",
            icon: FlagIcon,
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: false,
        },
        content: null,
    },
    {
        id: "jumeirah_beach",
        checkIsInGroup: true,
        groupName: ["landmarks"],
        zoomLevel: null,
        coords: { lat: 25.19391473532379, lng: 55.231646559007714 },
        polygonCoords: null,
        icon: {
            label: "jumeirah_beach",
            bgColor: WHITE_BG_COLOR,
            textColor: BLACK_TEXT_COLOR,
            align: "left",
            icon: FlagIcon,
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: false,
        },
        content: null,
    },
    {
        id: "nakheel_marinas",
        checkIsInGroup: true,
        groupName: ["landmarks"],
        zoomLevel: null,
        coords: { lat: 25.29042993499695, lng: 55.296927953490645 },
        polygonCoords: null,
        icon: {
            label: "nakheel_marinas",
            bgColor: WHITE_BG_COLOR,
            textColor: BLACK_TEXT_COLOR,
            align: "left",
            icon: FlagIcon,
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: false,
        },
        content: null,
    },
    {
        id: "the_view_at_the_palm",
        checkIsInGroup: true,
        groupName: ["landmarks"],
        zoomLevel: null,
        coords: { lat: 25.112681170745817, lng: 55.13878120002334 },
        polygonCoords: null,
        icon: {
            label: "the_view_at_the_palm",
            bgColor: WHITE_BG_COLOR,
            textColor: BLACK_TEXT_COLOR,
            align: "left",
            icon: FlagIcon,
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: false,
        },
        content: null,
    },
    {
        id: "palm_monorail",
        checkIsInGroup: true,
        groupName: ["landmarks"],
        zoomLevel: null,
        coords: { lat: 25.100156205311755, lng: 55.15649614474076 },
        polygonCoords: null,
        icon: {
            label: "palm_monorail",
            bgColor: WHITE_BG_COLOR,
            textColor: BLACK_TEXT_COLOR,
            align: "bottom",
            icon: FlagIcon,
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: false,
        },
        content: null,
    },
    {
        id: "nakheel_sales",
        checkIsInGroup: true,
        groupName: ["landmarks"],
        zoomLevel: null,
        coords: { lat: 25.103536132427655, lng: 55.15330807676427 },
        polygonCoords: null,
        icon: {
            label: "nakheel_sales",
            bgColor: WHITE_BG_COLOR,
            textColor: BLACK_TEXT_COLOR,
            align: "right",
            icon: FlagIcon,
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: false,
        },
        content: null,
    },
    {
        id: "dwc_airport",
        checkIsInGroup: true,
        groupName: ["landmarks"],
        zoomLevel: null,
        coords: { lat: 24.885818090910437, lng: 55.158727077847054 },
        polygonCoords: null,
        icon: {
            label: "dwc_airport",
            bgColor: WHITE_BG_COLOR,
            textColor: BLACK_TEXT_COLOR,
            align: "top",
            icon: PlaneIcon,
        },
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: false,
        },
        content: null,
    },
];

export const polylines = [
    {
        id: "bus_station_1",
        path: bus_road_1,
        stops: bus_road_1_stops,
        icon: MarkBus1,
        color: "#EC5650",
    },
    {
        id: "bus_station_1_2",
        path: bus_road_1_2,
        stops: [],
        icon: MarkBus1,
        color: "#EC5650",
    },
    {
        id: "bus_station_2",
        path: bus_road_2,
        stops: bus_road_2_stops,
        icon: MarkBus2,
        color: "#49CD57",
    },
    {
        id: "bus_station_3",
        path: bus_road_3,
        stops: bus_road_3_stops,
        icon: MarkBus3,
        color: "#F26E24",
    },
];
