export const translationsZh = {
    menu_developments: "项目开发",
    menu_developments_nakheel: "纳西尔",
    menu_developments_meydan: "美丹",

    menu_project_type: "项目类型",
    menu_project_type_villas: "别墅",
    menu_project_type_apartments: "公寓",
    menu_project_type_plots: "地皮",
    menu_project_type_apartments_villas: "公寓 ＆ 地皮",
    menu_project_type_plots_villas_apartments: "地皮＆别墅＆公寓",
    menu_project_type_show_all: "展示全部",

    menu_project_infrastructure: "基础设施",
    menu_project_infrastructure_landmarks: "地标",
    menu_project_infrastructure_transport: "交通 (地铁)",
    menu_project_infrastructure_education: "教育",
    menu_project_infrastructure_health: "健康",
    menu_project_infrastructure_retail_or_shopping: "购物",

    menu_show_master_communities: "主开发社区",
    menu_show_distances: "展示距离",
    menu_show_circles: "展示圆圈",
    menu_new_projects: "新项目",

    map_minutes: "分钟",
    map_kilometers: "公里",

    card_gallery: "图库",
    card_video: "视频",
    card_floor_plans: "楼层规划图",
    card_brochure: "楼书",
    card_guidelines: "指引",
    card_plots: "地皮",
    card_mat_board: "材料版楼书",
    card_mat_board_brochure: "材料版楼书",
    card_masterplan: "整体规划",
    card_fact_sheet: "资料页",
    card_payment_plan: "付款计划",
    card_floor_plans_and_brochure: "楼层规划&楼书",

    canal_front_residences: "运河景观公寓",

    palm_jebel_ali: "杰贝阿里棕榈岛",
    palm_jebel_ali_desc: "",
    palm_jebel_ali_plc: "PLC",
    palm_jebel_ali_dcr: "DCR",
    palm_jebel_ali_ukl: "UKL",
    palm_jebel_ali_mnop: "MNOP",

    veneto: "威尼托社区",
    palm_jumeirah: "朱美拉棕榈岛",

    como_residences: "科莫大厦",
    como_residences_desc:
        "作为著名的科莫酒店及度假村品牌的一部分，科莫公寓拥有无与伦比的设施和服务，为居民提供世界一流的生活体验。令人惊叹的高层塔楼享有迪拜天际线的壮丽景色。",

    the_palm_tower_residences: "棕榈塔公寓",
    st_regis_dubai: "迪拜棕榈岛瑞吉酒店",

    palm_beach_towers: "棕榈海滩公寓",
    palm_beach_towers_desc: "",
    palm_beach_towers_tower_1: "1号楼",
    palm_beach_towers_tower_2: "2号楼",
    palm_beach_towers_tower_3: "3号楼",

    jumeirah_village_triangle: "朱美拉三角村",
    jumeirah_village_circle: "朱美拉圆形村",
    jumeirah_heights: "朱美拉高楼住宅社区",
    jumeirah_islands: "朱美拉群岛别墅社区",
    jumeirah_park: "朱美拉公园社区",
    discovery_gardens: "愉景花园社区",
    masakin_al_furjan: "马萨金·阿尔·富尔詹社区",
    al_furjan: "阿尔·富尔詹",
    murooj_al_furjan: "穆鲁伊·阿尔·富尔詹",

    jebel_ali_village: "杰贝阿里村",
    jebel_ali_village_desc: "",
    jebel_ali_village_masterplan_a3: "MASTERPLAN A3",

    tilal_al_furjan: "蒂拉尔·阿尔富尔詹",
    tilal_al_furjan_desc: "",

    the_gardens: "花园社区",
    jebel_ali_club: "杰贝阿里俱乐部",
    the_world: "世界岛",

    dubai_islands: "迪拜群岛",
    dubai_islands_desc: "",
    dubai_islands_island_a: "DUBAI ISLAND A",

    rixos: "迪拜群岛RIXOS联名酒店 住宅",
    rixos_desc:
        "Rixos Dubai Islands Hotel & Residences是一个高级开发项目，在迪拜群岛提供海滨住宅。提供的住宅包括 1-3 居室公寓、2-3 居室海滨别墅和少量的独栋别墅。Rixos Dubai Islands Hotel & Residences by Nakheel的住宅设施，核心和点单服务将让您进入度假胜地般的生活。",
    rixos_a1: "P2",
    rixos_a2: "B2",
    rixos_a3: "B3",
    rixos_a4: "B4",
    rixos_beach_houses: "Beach Houses",
    rixos_villas: "Villas",

    greenwood: "格林伍德",
    greenwood_desc:
        "迪拜的格林伍德是一个富有远见的城市开发项目，将创新、可持续性和奢华融入这座充满活力的城市的中心。这片广阔的绿洲经过精心设计，与周围的沙漠景观相协调，通过融合尖端建筑、绿色空间和最先进的设施，重新定义了现代生活。",

    warsan_village: "瓦尔桑",
    international_city: "国际城",

    district_11: "美丹11区",
    district_11_desc: "",

    nad_al_sheba_villas: "纳德阿尔谢巴别墅社区",

    district_one_west: "美丹一区西区",
    district_one_west_desc:
        "Nakheel 展示了迪拜 美丹酋长城一区西区的史诗般的生活方式，以激发一个新的发展时代，提供 4 居室和 5 居室别墅以及 6 居室和 7 居室豪宅。快来在迪拜最负盛名的社区之一中亲自探索最高级的奢华水平。在一个崇高的封闭式社区中体验非凡的生活方式，为居民提供无可挑剔的房产和优质住宅。",

    golf_course: "高尔夫球场",
    meydan_hotel: "美丹酒店及马场看台",
    polo_residence: "波罗公寓",

    meydan_avenue: "美丹大道",
    meydan_avenue_desc: "",
    meydan_avenue_plot_a: "地皮A",
    meydan_avenue_plot_b: "地皮B",
    meydan_avenue_plot_c: "地皮C",
    meydan_avenue_plot_design: "地皮设计",
    meydan_avenue_plot_dev_control: "开发控制",
    meydan_avenue_plot_brochure: "楼书",

    lagoon_views: "水晶湖景观",
    lagoon_views_desc: "",

    district_one: "穆罕默德 本 拉希德 阿勒马克图姆城 一区",

    naya_residence: "娜娅公寓",
    naya_residence_desc: "",
    naya_residence_1_floor: "Naya 1 Floor",
    naya_residence_2_floor: "Naya 2 Floor",
    naya_residence_3_floor: "Naya 3 Floor",
    naya_residence_villas: "Villas",

    nakheel_sales: "Nakheel售楼部",
    jumeirah_beach: "朱美拉海滩",
    dwc_airport: "阿勒马克图姆国际机场",
    dxb_airport: "迪拜国际机场",
    the_view_at_the_palm: "朱美拉棕榈岛观景台",
    palm_monorail: "朱美拉棕榈岛单轨铁路",
    nakheel_marinas: "迪拜群岛Nakheel游艇港",
    dubai_canal: "迪拜运河",
};
