export const translationsEn = {
    menu_developments: "Developments",
    menu_developments_nakheel: "Nakheel",
    menu_developments_meydan: "Meydan",

    menu_project_type: "Project Type",
    menu_project_type_villas: "Villas",
    menu_project_type_apartments: "Apartments",
    menu_project_type_plots: "Plots",
    menu_project_type_apartments_villas: "Apartments & Villas",
    menu_project_type_plots_villas_apartments: "Plots & Villas & Apartments",
    menu_project_type_show_all: "Show All",

    menu_project_infrastructure: "Infrastructure",
    menu_project_infrastructure_landmarks: "Landmarks",
    menu_project_infrastructure_transport: "Transport (Metro)",
    menu_project_infrastructure_education: "Education",
    menu_project_infrastructure_health: "Health",
    menu_project_infrastructure_retail_or_shopping: "Retail or Shopping",

    menu_show_master_communities: "Master Communities",
    menu_show_distances: "Show Distances",
    menu_show_circles: "Show Circles",
    menu_new_projects: "New Projects",

    map_minutes: "min",
    map_kilometers: "km",

    card_gallery: "GALLERY",
    card_video: "VIDEO",
    card_floor_plans: "FLOOR PLANS",
    card_brochure: "BROCHURE",
    card_guidelines: "GUIDELINES",
    card_plots: "PLOTS",
    card_mat_board: "MAT. BOARD",
    card_mat_board_brochure: "MATERIAL BOARD BROCHURE",
    card_masterplan: "MASTERPLAN",
    card_fact_sheet: "FACT SHEET",
    card_payment_plan: "PAYMENT PLAN",
    card_floor_plans_and_brochure: "FLOOR PLANS & BROCHURE",

    canal_front_residences: "Canal Front Residences",

    palm_jebel_ali: "Palm Jebel Ali",
    palm_jebel_ali_desc:
        "The new, futuristic Palm Jebel Ali will raise the global benchmark in waterfront living, featuring extensive green spaces and luxury lifestyle amenities. It will feature mixed-use walkable neighbourhoods and incorporate smart city technologies and provide a diverse range of mobility options for residents, visitors and communities.",
    palm_jebel_ali_plc: "PLC",
    palm_jebel_ali_dcr: "DCR",
    palm_jebel_ali_ukl: "UKL",
    palm_jebel_ali_mnop: "MNOP",

    veneto: "Veneto",
    palm_jumeirah: "Palm Jumeirah",

    como_residences: "Como Residences",
    como_residences_desc:
        "As part of the renowned Como Hotels and Resorts brand, Como Residences boasts unparalleled amenities and services tailored to provide residents with a world-class living experience. The stunning high-rise towers offer breathtaking views of the Dubai skyline.",

    the_palm_tower_residences: "The Palm Tower Residences",
    st_regis_dubai: "St.Regis Dubai - The Palm",

    palm_beach_towers: "Palm Beach Towers",
    palm_beach_towers_desc:
        "Located at the entrance of Palm Jumeirah, the luxurious residential towers feature residences with stunning 360° views and a variety of nearby dining, leisure and recreational facilities. It offers a selection of one, two and three bedroom residences fully furnished and offering a wide range of state of the art amenities to complement ultra modern contemporary living. Every residence at Palm Beach Towers offers sunlit spaces, world class fixtures and views overlooking the spectacular beachfront.",
    palm_beach_towers_tower_1: "Tower 1",
    palm_beach_towers_tower_2: "Tower 2",
    palm_beach_towers_tower_3: "Tower 3",

    jumeirah_village_triangle: "Jumeirah Village Triangle",
    jumeirah_village_circle: "Jumeirah Village Circle",
    jumeirah_heights: "Jumeirah Heights",
    jumeirah_islands: "Jumeirah Islands",
    jumeirah_park: "Jumeirah Park",
    discovery_gardens: "Discovery Gardens",
    masakin_al_furjan: "Masakin Al Furjan",
    al_furjan: "Al Furjan",
    murooj_al_furjan: "Murooj Al Furjan",

    jebel_ali_village: "Jebel Ali Village",
    jebel_ali_village_desc:
        "Jebel Ali Village is an upscale residential development set amongst rolling hills and lush green spaces. A luxurious living experience that considers all aspects of your lifestyle, Jebel Ali Village is a community offering spacious homes, open plan layouts, undulating green landscapes and a myriad of amenities. It features both four and five bedroom villas, all with single row exclusivity.",
    jebel_ali_village_masterplan_a3: "MASTERPLAN A3",

    tilal_al_furjan: "Tilal Al Furjan",
    tilal_al_furjan_desc:
        "Welcome to Tilal Al Furjan, where a new level of modern elegance marks every home. Comprised of two gated communities that overlook the Al Furjan master community, its master plan raises every bar. Four and five bedroom villas are expansive in their layouts and express minimalism and the finest decor. An ocean of lush green landscaping connects the community, creating wide open spaces where tailored shared facilities, from pools to playgrounds, take on a life of their own.",

    the_gardens: "The Gardens",
    jebel_ali_club: "Jebel Ali Club",
    the_world: "The World",

    dubai_islands: "Dubai Islands",
    dubai_islands_desc: "",
    dubai_islands_island_a: "DUBAI ISLAND A",

    rixos: "Rixos Dubai Islands Hotes and Residences",
    rixos_desc:
        "Rixos Dubai Islands Hotel & Residences is a premium development offering waterfront residences on Dubai Islands. Among residences on offer are 1–3 bedroom apartments, 2-3 bedroom beach houses and a limited collection of stand-alone villas. Residential amenities, core and a la carte services of Rixos Dubai Islands Hotel & Residences by Nakheel will let you emerge into resort-like living.",
    rixos_a1: "A1",
    rixos_a2: "A2",
    rixos_a3: "A3",
    rixos_a4: "A4",
    rixos_beach_houses: "Beach Houses",
    rixos_villas: "Villas",

    greenwood: "Greenwood",
    greenwood_desc:
        "Greenwood in Dubai is a visionary urban development that encapsulates innovation, sustainability, and luxury in the heart of this dynamic city. This sprawling oasis, carefully designed to harmonize with the surrounding desert landscape, redefines modern living through a blend of cutting-edge architecture, green spaces, and state-of-the-art amenities.",

    warsan_village: "Warsan",
    international_city: "International City",

    district_11: "District 11",
    district_11_desc:
        "District 11 Opal Gardens enlivens the spirit with verdant green spaces encircling a stunning crystal lagoon. Secure and gated, the development will feature well-crafted villas and townhouses surrounded by lush landscaping and over 5 kilometers of cycling and pedestrian trails. The residences at District 11 Opal Gardens are impeccably designed and feature stunning views of the Dubai skyline and crystal lagoon.",

    nad_al_sheba_villas: "Nad Al Sheba Villas",

    district_one_west: "District One West",
    district_one_west_desc:
        "The epic lifestyle of District One West at MBR City, Dubai is presented by Nakheel to inspire a new era of development offering 4 & 5 bedroom villas and 6 & 7 bedroom mansions. Come and discover by yourself the most enhanced level of luxury in one of the most prestigious communities of Dubai. Experience the extraordinary lifestyle in a sublime, gated community with an epic collection of impeccable properties and premium homes for residents.",

    golf_course: "Golf Course",
    meydan_hotel: "The Meydan Hotel and Grandstand",
    polo_residence: "Polo Residence",

    meydan_avenue: "Meydan Avenue",
    meydan_avenue_desc: "",
    meydan_avenue_plot_a: "Plot A",
    meydan_avenue_plot_b: "Plot B",
    meydan_avenue_plot_c: "Plot C",
    meydan_avenue_plot_design: "Plot Design",
    meydan_avenue_plot_dev_control: "Development Control",
    meydan_avenue_plot_brochure: "Brochure",

    lagoon_views: "Lagoon Views",
    lagoon_views_desc:
        "Mirroring the resplendent glory of the crystal-blue waters, the opulent residential tower of Lagoon Views at District One is designed to offer discerning buyers a truly momentous living experience. This tallest tower of District One Residences features one, two-, three-, and four-bedroom apartments, as well as and five-bedroom penthouses with expansive terraces overlooking the tranquil lagoon.",

    district_one: "Mohamed Bin Rashid Al Maktoum City - District One",

    naya_residence: "Naya Residence",
    naya_residence_desc:
        "At the edge of man-made crystal lagoon stands Naya at District One, a one-of-a-kind resort-inspired oasis. Part of the prestigious Mohammed Bin Rashid Al Maktoum City, Naya at District One offers a lifestyle grounded in wellness along the shoreline of pristine waters.",
    naya_residence_1_floor: "Naya 1 Floor",
    naya_residence_2_floor: "Naya 2 Floor",
    naya_residence_3_floor: "Naya 3 Floor",
    naya_residence_villas: "Villas",

    nakheel_sales: "Nakeel Sales Centre",
    jumeirah_beach: "Jumeirah Beach",
    dwc_airport: "DWC Airport",
    dxb_airport: "DXB Airport",
    the_view_at_the_palm: "The View at The Palm",
    palm_monorail: "The Palm Monorail",
    nakheel_marinas: "Nakheel Marinas Dubai Islands",
    dubai_canal: "Dubai Canal",
};
