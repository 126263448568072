import React, { useEffect, useState } from "react";

import { styled } from "styled-components";
import ReactLoading from "react-loading";

import "./Loading.scss";

const LoadingDiv = styled.div`
    opacity: ${({ isLoadedMap, isLoadedMin }) =>
        isLoadedMap && isLoadedMin ? "0%" : "100%"};
    pointer-events: ${({ isLoadedMap, isLoadedMin }) =>
        isLoadedMap && isLoadedMin ? "none" : "all"};
`;

export default function Loading({ isLoadedMap }) {
    const [isLoadedMin, setIsLoadedMin] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setIsLoadedMin(true);
        }, 700);
    }, []);

    return (
        <LoadingDiv
            className="loading"
            isLoadedMap={isLoadedMap}
            isLoadedMin={isLoadedMin}
        >
            <ReactLoading
                type="bars"
                color="#09617F"
                height={100}
                width={100}
            />
        </LoadingDiv>
    );
}
