import React from "react";
import { styled } from "styled-components";

import "./BottomTips.scss";

import NakheelQR from "../../../static/images/nakheel-qr.png";

import MarkHospital from "../../../static/images/mark-hospital.svg";
import MarkSchool from "../../../static/images/mark-school.svg";
import MarkShopping from "../../../static/images/mark-shopping.svg";

import SatelliteView from "../../../static/images/satellite-view.png";
import DefaultView from "../../../static/images/default-view.png";

function BottomTipsMark({ Image, label }) {
    return (
        <div className="bottom-tips-mark">
            <div className="bottom-tips-mark-image">
                <img src={Image} alt="mark-image" />
            </div>
            <div className="bottom-tips-mark-label">
                <span className="bottom-tips-mark-label-text">{label}</span>
            </div>
        </div>
    );
}

const MapTypeDiv = styled.div`
    background-image: url(${({ status }) =>
        status ? DefaultView : SatelliteView});
`;

function BottomTipsMapType({ mapTypeId, setMapTypeId }) {
    let status = mapTypeId === "satellite";

    return (
        <MapTypeDiv
            className="bottom-tips-maptype"
            status={status}
            onClick={() => setMapTypeId(status ? "roadmap" : "satellite")}
        />
    );
}

export default function BottomTips({
    checkboxFiltersRadio,
    mapTypeId,
    setMapTypeId,
}) {
    return (
        <div className="bottom-tips">
            <div className="bottom-tips-qr">
                <img src={NakheelQR} alt="nakheel-qr" />
            </div>
            {/* <div className="bottom-tips-marks">
                {
                    checkboxFiltersRadio.includes('health') && (
                        <BottomTipsMark Image={MarkHospital} label={'Hospital'} />
                    )
                }
                {
                    checkboxFiltersRadio.includes('education') && (
                        <BottomTipsMark Image={MarkSchool} label={'School'} />
                    )
                }
                {
                    checkboxFiltersRadio.includes('retail_or_shopping') && (
                        <BottomTipsMark Image={MarkShopping} label={'Retails'} />
                    )
                }
            </div> */}
            <BottomTipsMapType
                mapTypeId={mapTypeId}
                setMapTypeId={setMapTypeId}
            />
        </div>
    );
}
