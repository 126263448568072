import React from "react";
import { useState, useEffect, useRef } from "react";
import { useSwipeable } from "react-swipeable";
import { useTranslation } from "react-i18next";

import { styled } from "styled-components";

import "./Card.scss";

import ExitCross from "../../../static/images/card-exit-cross.svg";
import ButtonArrow from "../../../static/images/card-arrow.svg";
import CardQR from "../../../static/images/nakheel-qr.png";
import NakheelLogo from "../../../static/images/nakheel_logo.png";
import CardLinkLogo from "../../../static/images/card-link-logo.svg";

import {
    WindowCardGallery,
    WindowCardImagesFile,
} from "../window-card/WindowCard";
import { Link } from "react-router-dom";

const CircleDiv = styled.div`
    background-color: ${({ status }) =>
        status ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.5)"};
`;

function CardTopCircles({
    previews,
    setImageIndex,
    activeCircle,
    setActiveCircle,
}) {
    return (
        <div className="card-top-circles">
            {previews.map((preview) => (
                <CircleDiv
                    status={activeCircle === preview}
                    className="card-top-circle"
                    onClick={() => {
                        setActiveCircle(preview);
                        setImageIndex(previews.indexOf(preview));
                    }}
                ></CircleDiv>
            ))}
        </div>
    );
}

function CardText({ text, id }) {
    return (
        <div className={"card-" + id}>
            <span className={"card-" + id + "-text"}>{text}</span>
        </div>
    );
}

function CardTextImage({ text, id, image }) {
    return (
        <div className={"card-" + id}>
            <span className={"card-" + id + "-text"}>{text}</span>
            <img
                src={image}
                alt={"card-" + id + "-logo"}
                className={"card-" + id + "-image"}
            />
        </div>
    );
}

const CardButtonDiv = styled.div`
    max-width: ${({ width }) => (width ? width : "48%")};
`;

function CardBottomButton({ text, width, openWindow, content }) {
    return (
        <CardButtonDiv
            className="card-bottom-button"
            width={width}
            onClick={() => {
                openWindow(content);
            }}
        >
            <div width={width} className="card-bottom-button-inner">
                <span className="card-bottom-button-text">{text}</span>
                <img src={ButtonArrow} alt="button-arrow" />
            </div>
        </CardButtonDiv>
    );
}

const CardPDFMessageDiv = styled.div`
    max-width: 90%;

    margin: 0 auto;
    margin-top: 65px;

    color: #000000;
    line-height: 145%;
`;

function CardPDFMessage({ PDFLink }) {
    return (
        <CardPDFMessageDiv>
            <p>This browser does not support inline PDFs.</p>
            <p>
                Please download the PDF to view it:{" "}
                <a href={PDFLink} target="_blank" rel="noopener noreferrer">
                    Download PDF
                </a>
            </p>
        </CardPDFMessageDiv>
    );
}

const CardDiv = styled.div`
    opacity: ${({ status }) => (status ? "1" : "0")};
    pointer-events: ${({ status }) => (status ? "all" : "none")};
    transform: ${({ status }) =>
        status ? "translateY(0)" : "translateY(50px)"};
`;

const CardTopDiv = styled.div`
    background-image: url(${({ image }) => image});
`;

export default function Card({
    activeCard,
    setActiveCard,
    cardContent,
    activeWindow,
    setActiveWindow,
    setWindowContent,
}) {
    const [t, i18] = useTranslation();

    // Videos
    const videoRef = useRef(null);

    // Open PDF or IMAGE
    function openFile(data, filesType) {
        return (
            <>
                {filesType === "pdf" ? (
                    !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                        navigator.userAgent
                    ) ? (
                        <iframe
                            src={data}
                            width="100%"
                            height="100%"
                            title="PDF Viewer"
                        />
                    ) : (
                        <CardPDFMessage PDFLink={data} />
                    )
                ) : (
                    filesType === "img" && <WindowCardImagesFile data={data} />
                )}
            </>
        );
    }

    // Images
    const [activeImage, setActiveImage] = useState();
    const [imageIndex, setImageIndex] = useState(0);
    const [activeCircle, setActiveCircle] = useState();
    useEffect(() => {
        if (cardContent.previews.length === 0) {
            setActiveImage(NakheelLogo);
        } else {
            setActiveImage(cardContent.previews[0]);
        }
        setActiveCircle(cardContent.previews[0]);
    }, [cardContent]);

    useEffect(() => {
        let image = cardContent.previews[imageIndex];
        setActiveImage(image);
        setActiveCircle(image);
    }, [imageIndex]);

    const changeImage = (action) => {
        if (!(cardContent.previews.length === 0)) {
            let nextIndex = imageIndex + action;
            if (nextIndex > cardContent.previews.length - 1) {
                setImageIndex(0);
            } else if (nextIndex < 0) {
                setImageIndex(cardContent.previews.length - 1);
            } else {
                setImageIndex(nextIndex);
            }
        }
    };

    const openWindow = (content) => {
        if (content) {
            setActiveCard(false);
            setWindowContent(content);
            setActiveWindow(true);
        }
    };

    useEffect(() => {
        try {
            videoRef.current.pause();
        } catch {}
    }, [activeWindow]);

    // Swipe handlers
    const handlers = useSwipeable({
        onSwipedLeft: () => changeImage(1),
        onSwipedRight: () => changeImage(-1),
        swipeDuration: 500,
        preventScrollOnSwipe: true,
        preventDefaultTouchmoveEvent: true,
        trackMouse: false,
    });

    let buttonsLocalized = [];
    if (cardContent.buttons) {
        if (cardContent.translations) {
            if (cardContent.buttons[i18.language]) {
                buttonsLocalized = cardContent.buttons[i18.language];
            } else {
                buttonsLocalized = cardContent.buttons.en;
            }
        } else {
            buttonsLocalized = cardContent.buttons;
        }
    }

    return (
        <div className="card">
            <CardDiv status={activeCard} className="card-container">
                <div className="card-exit" onClick={() => setActiveCard(null)}>
                    <img src={ExitCross} alt="exit-cross" />
                </div>
                <Link className="card-qr" to={cardContent.link}>
                    <img src={CardQR} alt="card-qr" />
                </Link>
                <CardTopDiv
                    className="card-top"
                    image={activeImage}
                    {...handlers}
                >
                    <CardTopCircles
                        previews={cardContent.previews}
                        setImageIndex={setImageIndex}
                        activeCircle={activeCircle}
                        setActiveCircle={setActiveCircle}
                    />
                    <div
                        className="card-top-back"
                        onClick={() =>
                            openWindow(
                                !(cardContent.previews.length === 0) ? (
                                    <WindowCardGallery
                                        cardActiveImageIndex={imageIndex}
                                        images={cardContent.previews}
                                        setActiveWindow={setActiveWindow}
                                        setActiveCard={setActiveCard}
                                        setCardImageIndex={setImageIndex}
                                    />
                                ) : (
                                    false
                                )
                            )
                        }
                    />
                </CardTopDiv>
                <div className="card-bottom">
                    <Link
                        className="card-title"
                        to={cardContent.link}
                        target={cardContent.link && "_blank"}
                    >
                        <CardTextImage
                            text={t(cardContent.title)}
                            id="title-label"
                            image={CardLinkLogo}
                        />
                    </Link>
                    <CardText text={t(cardContent.desc)} id="desc" />
                    <div className="card-bottom-buttons">
                        <CardBottomButton
                            text={t("card_gallery")}
                            openWindow={openWindow}
                            content={
                                !(cardContent.previews.length === 0) ? (
                                    <WindowCardGallery
                                        cardActiveImageIndex={imageIndex}
                                        images={cardContent.previews}
                                        setActiveWindow={setActiveWindow}
                                        setActiveCard={setActiveCard}
                                        setCardImageIndex={setImageIndex}
                                    />
                                ) : (
                                    false
                                )
                            }
                        />
                        <CardBottomButton
                            text={t("card_video")}
                            openWindow={openWindow}
                            content={
                                cardContent.video ? (
                                    <video
                                        width="100%"
                                        height="100%"
                                        controls
                                        ref={videoRef}
                                    >
                                        <source
                                            src={cardContent.video}
                                            type="video/mp4"
                                        />
                                    </video>
                                ) : (
                                    false
                                )
                            }
                        />
                    </div>
                    {buttonsLocalized?.map((button) =>
                        button.type === "double" ? (
                            <div className="card-bottom-buttons">
                                <CardBottomButton
                                    text={t(button.data[0].text)}
                                    openWindow={openWindow}
                                    content={openFile(
                                        button.data[0].data,
                                        button.data[0].type
                                    )}
                                />
                                <CardBottomButton
                                    text={t(button.data[1].text)}
                                    openWindow={openWindow}
                                    content={openFile(
                                        button.data[1].data,
                                        button.data[1].type
                                    )}
                                />
                            </div>
                        ) : (
                            button.type === "single" && (
                                <CardBottomButton
                                    text={t(button.data[0].text)}
                                    openWindow={openWindow}
                                    content={openFile(
                                        button.data[0].data,
                                        button.data[0].type
                                    )}
                                    width="100%"
                                />
                            )
                        )
                    )}
                </div>
            </CardDiv>
        </div>
    );
}
