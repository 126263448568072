import React, { useEffect, useState } from "react";

import styled from "styled-components";
import Switch from "react-switch";

import "./Menu.scss";

import NakheelLogo from "../../../static/images/nakheel.png";
import NakheelBlueLogo from "../../../static/images/nakheel-blue-logo.png";
import DropdownArrow from "../../../static/images/dropdown-arrow.svg";
import MasterCommunities from "../../../static/images/master-communities.svg";
import LanguagePlanet from "../../../static/images/language.svg";

import MenuBurgerOpened from "../../../static/images/menu-burger-opened.svg";
import MenuBurgerClosed from "../../../static/images/menu-burger-closed.svg";

import { resources } from "../../../data/locales/resources";
import { useTranslation } from "react-i18next";
import LanguageDropdown from "./language/Language";

// Menu Elements

function MenuLanguageButton({ link, text }) {
    return (
        // <Link className="menu-language-button" to={link} replace>
        <a className="menu-language-button" href={link} replace>
            <div className="menu-language-inner">
                <div className="menu-language-image">
                    <img src={LanguagePlanet} alt="language-planet" />
                </div>
                <div className="menu-language-label">
                    <span className="menu-language-label-text">{text}</span>
                </div>
            </div>
        </a>
    );
}

function MenuLanguage({ i18 }) {
    const resourcesList = Object.entries(resources);

    return (
        <div className="menu-language">
            {resourcesList.map(
                (resource) =>
                    i18.language !== resource[0] && (
                        <MenuLanguageButton
                            link={"/" + resource[0] + "/"}
                            text={resource[1].title}
                        />
                    )
            )}
        </div>
    );
}

function MenuLogo({ mapTypeId }) {
    return (
        <div onClick={() => window.location.reload()} className="menu-logo">
            <div className="menu-logo-top">
                <img
                    src={
                        mapTypeId === "satellite"
                            ? NakheelLogo
                            : NakheelBlueLogo
                    }
                    alt="nakheel-logo"
                />
            </div>
            {/* <div className="menu-logo-bottom">
                <span className="menu-logo-text">Dubai, United Arab Emirates</span>
            </div> */}
        </div>
    );
}

// Dropdown Elements

const MenuDropdownButtonDiv = styled.div`
    border: 1px solid
        ${({ isOpened }) => (isOpened ? "#e6e2e2e5" : "#e6e2e2a8")};
`;

const MenuDropdownArrowImg = styled.img`
    transform: ${({ isOpened }) => (isOpened ? "rotate(180deg)" : "rotate(0)")};
`;

function MenuDropdownButton({ onClick, title, currentActive, type, isOpened }) {
    const removeExtraChars = () => {
        let extraCharsAmount = title.length + currentActive.length + 2 - 27;
        if (extraCharsAmount > 0) {
            return currentActive.slice(0, -extraCharsAmount - 2) + "..";
        } else {
            return currentActive;
        }
    };

    return (
        <MenuDropdownButtonDiv
            className="menu-dropdown-button"
            onClick={onClick}
            isOpened={isOpened}
        >
            <div className="menu-dropdown-button-inner">
                <span className="menu-dropdown-button-text">
                    {type === "radio" ? (
                        <>
                            {title}: <b>{removeExtraChars()}</b>
                        </>
                    ) : (
                        title
                    )}
                </span>
                <MenuDropdownArrowImg
                    isOpened={isOpened}
                    src={DropdownArrow}
                    alt="dropdown-arrow"
                />
            </div>
        </MenuDropdownButtonDiv>
    );
}

function MenuDropdownListTitle({ title }) {
    return (
        <div className="menu-block-list-title">
            <span className="menu-block-title-text">{title}</span>
        </div>
    );
}

// Dropdown Radio-List

const RadioInner = styled.div`
    opacity: ${({ activeStatus }) => (activeStatus ? "1" : "0")};
`;

function MenuRadioButton({ activeStatus }) {
    return (
        <div className="menu-block-list-radio-button">
            <RadioInner
                activeStatus={activeStatus}
                className="menu-block-list-radio-button-inner"
            />
        </div>
    );
}

function MenuDropdownListRadio({ elements, stateObject }) {
    return (
        <>
            {elements.map((element) => {
                let activeStatus = element.id === stateObject.state.id;
                return (
                    <div
                        onClick={() => stateObject.setState(element)}
                        className="menu-dropdown-list-element"
                        activeStatus={activeStatus}
                    >
                        <div className="menu-dropdown-list-element-inner">
                            <MenuRadioButton activeStatus={activeStatus} />
                            <span className="menu-dropdown-list-element-text">
                                {element.text}
                            </span>
                        </div>
                    </div>
                );
            })}
        </>
    );
}

// Dropdown Checkbox-List

const ChechboxInner = styled.div`
    opacity: ${({ activeStatus }) => (activeStatus ? "1" : "0")};
`;

function MenuCheckboxButton({ activeStatus }) {
    return (
        <div className="menu-block-list-checkbox-button">
            <ChechboxInner
                activeStatus={activeStatus}
                className="menu-block-list-checkbox-button-inner"
            />
        </div>
    );
}

function MenuDropdownListCheckbox({ elements, stateObject }) {
    const editState = (elementId) => {
        let stateTemp = JSON.parse(JSON.stringify(stateObject.state));

        console.log(stateTemp);
        if (elementId.startsWith("_all")) {
            if (!stateTemp.includes(elementId)) {
                for (let element of elements) {
                    if (
                        !stateTemp.includes(element.id) &&
                        element.id !== elementId
                    ) {
                        stateTemp.push(element.id);
                    }
                }
            } else {
                for (let element of elements) {
                    if (
                        stateTemp.includes(element.id) &&
                        element.id !== elementId
                    ) {
                        stateTemp.splice(stateTemp.indexOf(element.id), 1);
                    }
                }
            }
        }

        if (stateObject.state.includes(elementId)) {
            stateTemp.splice(stateTemp.indexOf(elementId), 1);

            for (let element of elements) {
                if (
                    element.id.startsWith("_all") &&
                    stateTemp.includes(element.id)
                ) {
                    stateTemp.splice(stateTemp.indexOf(element.id), 1);
                }
            }
        } else {
            stateTemp.push(elementId);
        }

        if (
            elements.every(
                (element) =>
                    stateTemp.includes(element.id) ||
                    element.id.startsWith("_all") === true
            )
        ) {
            for (let element of elements) {
                if (
                    element.id.startsWith("_all") &&
                    !stateTemp.includes(element.id)
                ) {
                    stateTemp.push(element.id);
                }
            }
        }

        if (stateTemp.length === 0) {
            return JSON.parse(JSON.stringify(stateObject.state));
        }
        return stateTemp;
    };

    return (
        <>
            {elements.map((element) => {
                let activeStatus = stateObject.state.includes(element.id);
                return (
                    <div
                        onClick={() =>
                            stateObject.setState(editState(element.id))
                        }
                        className="menu-block-list-element"
                        activeStatus={activeStatus}
                    >
                        <div className="menu-block-list-element-inner">
                            <MenuCheckboxButton activeStatus={activeStatus} />
                            <span className="menu-block-list-element-text">
                                {element.text}
                            </span>
                        </div>
                    </div>
                );
            })}
        </>
    );
}

// Dropdown Element

function MenuBlock({ id, title, elements, stateObject }) {
    let elementsLen = elements.length;

    return (
        <div
            className={`menu-block menu-block-${id}`}
            elementsLen={elementsLen}
        >
            <div elementsLen={elementsLen} className="menu-block-list">
                <MenuDropdownListTitle title={title} />

                <div className="menu-block-list-elements">
                    <MenuDropdownListCheckbox
                        elements={elements}
                        stateObject={stateObject}
                    />
                </div>
            </div>
        </div>
    );
}

// Switch Element
function MenuSwitch({
    switchChecked,
    setSwitchChecked,
    setActiveCard,
    labelText,
    className,
}) {
    const switchChange = () => {
        setSwitchChecked(!switchChecked);
        setActiveCard(null);
    };

    return (
        <div className={`menu-switch ${className ? className : ""}`}>
            <div className="menu-switch-inner" onClick={switchChange}>
                <Switch
                    className="menu-switch-element"
                    onChange={() => null}
                    checked={switchChecked}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onColor="#FA854F"
                    offColor="#b3b3b3"
                    handleDiameter={15}
                    height={17}
                    width={31}
                />
                <div className="menu-switch-label">
                    <span className="menu-switch-text">{labelText}</span>
                </div>
            </div>
        </div>
    );
}

// Button Element

const MenuButtonDiv = styled.div`
    background-color: ${({ isActive }) =>
        isActive ? "#F88350" : "rgba(197, 197, 197, 0)"};
    // font-weight: ${({ isActive }) => (isActive ? "500" : "400")};
    // font-size: ${({ isActive }) => (isActive ? "12.25px" : "12px")};

    &:hover {
        background-color: ${({ isActive }) =>
            isActive ? "#DF7949" : "rgba(226, 226, 226, 0.153)"};
    }
`;

const MenuButtonImage = styled.img`
    transform: ${({ isActive }) => (isActive ? "rotate(-0.5turn)" : "0")};
`;

function MenuButton({ text, buttonChecked, setButtonCheched }) {
    return (
        <MenuButtonDiv
            className="menu-button"
            isActive={buttonChecked}
            onClick={() => setButtonCheched(!buttonChecked)}
        >
            <span className="menu-button-text">{text}</span>
            <MenuButtonImage
                src={MasterCommunities}
                alt="master-communities-logo"
                isActive={buttonChecked}
            />
        </MenuButtonDiv>
    );
}

function MenuBurger({ menuGroupOpened, setMenuGroupOpened }) {
    return (
        <div
            className="menu-burger"
            onClick={() => setMenuGroupOpened((state) => !state)}
        >
            {menuGroupOpened ? (
                <img src={MenuBurgerOpened} alt="burger" />
            ) : (
                <img src={MenuBurgerClosed} alt="burger" />
            )}
        </div>
    );
}

const MenuGroupDiv = styled.div`
    @media screen and (max-width: 500px) {
        transform: translate(
            -50%,
            ${({ isOpened }) => (isOpened ? "0%" : "-120%")}
        );
    }
`;

export default function Menu({
    data,

    directionSwitchChecked,
    setDirectionSwitchChecked,
    radiusSwitchChecked,
    setRadiusSwitchChecked,
    newProjectsSwitchChecked,
    setNewProjectsSwitchChecked,
    masterCommunitiesChecked,
    setMasterCommunitiesChecked,

    setActiveCard,

    mapTypeId,
}) {
    const [t] = useTranslation();
    const [menuGroupOpened, setMenuGroupOpened] = useState(false);

    return (
        <div className="menu">
            <MenuLogo mapTypeId={mapTypeId} />
            <MenuButton
                text={t("menu_show_master_communities")}
                buttonChecked={masterCommunitiesChecked}
                setButtonCheched={setMasterCommunitiesChecked}
            />
            <MenuBurger
                menuGroupOpened={menuGroupOpened}
                setMenuGroupOpened={setMenuGroupOpened}
            />
            <MenuGroupDiv isOpened={menuGroupOpened} className="menu-group">
                <div className="menu-blocks">
                    {data.map((dropdown) => (
                        <MenuBlock
                            id={dropdown.id}
                            title={dropdown.title}
                            elements={dropdown.elements}
                            stateObject={dropdown.stateObject}
                        />
                    ))}
                </div>
                <div className="menu-right-switchbox">
                    <MenuSwitch
                        switchChecked={directionSwitchChecked}
                        setSwitchChecked={setDirectionSwitchChecked}
                        setActiveCard={setActiveCard}
                        labelText={t("menu_show_distances")}
                    />
                    <MenuSwitch
                        switchChecked={radiusSwitchChecked}
                        setSwitchChecked={setRadiusSwitchChecked}
                        setActiveCard={setActiveCard}
                        labelText={t("menu_show_circles")}
                    />
                </div>
                <MenuSwitch
                    switchChecked={newProjectsSwitchChecked}
                    setSwitchChecked={setNewProjectsSwitchChecked}
                    setActiveCard={setActiveCard}
                    labelText={t("menu_new_projects")}
                    className={"menu-switch-new-projects"}
                />
                {/* <MenuLanguage i18={i18} /> */}
                <LanguageDropdown resources={resources} />
            </MenuGroupDiv>
        </div>
    );
}
