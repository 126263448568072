export const translationsPt = {
    menu_developments: "Desenvolvimentos",
    menu_developments_nakheel: "Nakheel",
    menu_developments_meydan: "Meydan",

    menu_project_type: "Tipo de projeto",
    menu_project_type_villas: "Villa",
    menu_project_type_apartments: "Apartamentos",
    menu_project_type_plots: "Lotes",
    menu_project_type_apartments_villas: "Apartamentos e Villas",
    menu_project_type_plots_villas_apartments: "Lotes e Villas e Apartamentos",
    menu_project_type_show_all: "Mostrar tudo",

    menu_project_infrastructure: "Infraestrutura",
    menu_project_infrastructure_landmarks: "Marcos",
    menu_project_infrastructure_transport: "Transporte (Metro)",
    menu_project_infrastructure_education: "Educação",
    menu_project_infrastructure_health: "Saúde",
    menu_project_infrastructure_retail_or_shopping: "Retalho ou Compras",

    menu_show_master_communities: "Comunidades principais	",
    menu_show_distances: "Mostrar distâncias",
    menu_show_circles: "Mostrar círculos",
    menu_new_projects: "Novos projetos",

    map_minutes: "min",
    map_kilometers: "km",

    card_gallery: "Galeria",
    card_video: "Vídeo",
    card_floor_plans: "Planos de piso",
    card_brochure: "Brochura",
    card_guidelines: "Guadline",
    card_plots: "Lotes",
    card_mat_board: "Brochura do quadro de material",
    card_mat_board_brochure: "Brochura do quadro de material",
    card_masterplan: "Plano",
    card_fact_sheet: "Folha de informação",
    card_payment_plan: "Plano de pagamento",
    card_floor_plans_and_brochure: "Planos de pisco e Brochura",

    canal_front_residences: "Residências em frente ao canal",

    palm_jebel_ali: "Palm Jebel Ali",
    palm_jebel_ali_desc: "",
    palm_jebel_ali_plc: "PLC",
    palm_jebel_ali_dcr: "DCR",
    palm_jebel_ali_ukl: "UKL",
    palm_jebel_ali_mnop: "MNOP",

    veneto: "Veneto",
    palm_jumeirah: "Palm Jumeirah",

    como_residences: "Como Residences",
    como_residences_desc:
        "Fazendo parte da famosa marca Como Hotels and Resorts, o Como Residences possui comodidades e serviços inigualáveis, concebidos para proporcionar aos residentes uma experiência de vida de classe mundial. As deslumbrantes torres de arranha-céus oferecem vistas deslumbrantes sobre o horizonte do Dubai.",

    the_palm_tower_residences: "The Palm Tower Residences",
    st_regis_dubai: "St.Regis Dubai - The Palm",

    palm_beach_towers: "Palm Beach Towers",
    palm_beach_towers_desc: "",
    palm_beach_towers_tower_1: "Torre 1",
    palm_beach_towers_tower_2: "Torre 2",
    palm_beach_towers_tower_3: "Torre 3",

    jumeirah_village_triangle: "Jumeirah Village Triangle",
    jumeirah_village_circle: "Jumeirah Village Circle",
    jumeirah_heights: "Jumeirah Heights",
    jumeirah_islands: "Jumeirah Islands",
    jumeirah_park: "Jumeirah Park",
    discovery_gardens: "Discovery Gardens",
    masakin_al_furjan: "Masakin Al Furjan",
    al_furjan: "Al Furjan",
    murooj_al_furjan: "Murooj Al Furjan",

    jebel_ali_village: "Jebel Ali Village",
    jebel_ali_village_desc: "",
    jebel_ali_village_masterplan_a3: "MASTERPLAN A3",

    tilal_al_furjan: "Tilal Al Furjan",
    tilal_al_furjan_desc: "",

    the_gardens: "The Gardens",
    jebel_ali_club: "Jebel Ali Club",
    the_world: "The World",

    dubai_islands: "Dubai Islands",
    dubai_islands_desc: "",
    dubai_islands_island_a: "DUBAI ISLAND A",

    rixos: "Rixos Dubai Islands Hotes and Residences",
    rixos_desc:
        "O Rixos Dubai Islands Hotel & Residences é um empreendimento de luxo que oferece residências à beira-mar nas Ilhas do Dubai. Entre as residências disponíveis encontram-se apartamentos com 1-3 quartos, casas de praia com 2-3 quartos e uma coleção limitada de moradias independentes. As comodidades residenciais, os serviços centrais e os serviços à la carte do Rixos Dubai Islands Hotel & Residences by Nakheel permitir-lhe-ão desfrutar de uma vida de resort.",
    rixos_a1: "A1",
    rixos_a2: "A2",
    rixos_a3: "A3",
    rixos_a4: "A4",
    rixos_beach_houses: "Beach Houses",
    rixos_villas: "Villas",

    greenwood: "Greenwood",
    greenwood_desc:
        "Greenwood, no Dubai, é um empreendimento urbano visionário que engloba inovação, sustentabilidade e luxo no coração desta cidade dinâmica. Este oásis extenso, cuidadosamente concebido para se harmonizar com a paisagem desértica circundante, redefine a vida moderna através de uma mistura de arquitetura de vanguarda, espaços verdes e comodidades topo de gama.",

    warsan_village: "Warsan",
    international_city: "International City",

    district_11: "District 11",
    district_11_desc: "",

    nad_al_sheba_villas: "Nad Al Sheba Villas",

    district_one_west: "District One West",
    district_one_west_desc:
        "O estilo de vida épico do District One West na MBR City, Dubai, é apresentado pela Nakheel para inspirar uma nova era de desenvolvimento, oferecendo moradias com 4 e 5 quartos e mansões com 6 e 7 quartos. Venha descobrir por si mesmo o nível mais elevado de luxo numa das comunidades mais prestigiadas do Dubai. Experimente o estilo de vida extraordinário num sublime condomínio fechado com uma coleção épica de propriedades impecáveis e casas de primeira qualidade para os residentes.",

    golf_course: "Golf Course",
    meydan_hotel: "The Meydan Hotel and Grandstand",
    polo_residence: "Polo Residence",

    meydan_avenue: "Meydan Avenue",
    meydan_avenue_desc: "",
    meydan_avenue_plot_a: "Lote A",
    meydan_avenue_plot_b: "Lote B",
    meydan_avenue_plot_c: "Lote C",
    meydan_avenue_plot_design: "Design de lote",
    meydan_avenue_plot_dev_control: "Controlo de desenvolvimento",
    meydan_avenue_plot_brochure: "Brochura",

    lagoon_views: "Lagoon Views",
    lagoon_views_desc: "",

    district_one: "Mohamed Bin Rashid Al Maktoum City - District One",

    naya_residence: "Naya Residence",
    naya_residence_desc: "",
    naya_residence_1_floor: "Naya 1 Floor",
    naya_residence_2_floor: "Naya 2 Floor",
    naya_residence_3_floor: "Naya 3 Floor",
    naya_residence_villas: "Villas",

    nakheel_sales: "Centro de Vendas Nakheel",
    jumeirah_beach: "Jumeirah Beach",
    dwc_airport: "Aeroporto DWC",
    dxb_airport: "Aeroporto DXB",
    the_view_at_the_palm: "The View at The Palm",
    palm_monorail: "The Palm Monorail",
    nakheel_marinas: "Nakheel Marinas Dubai Islands",
    dubai_canal: "Dubai Canal",
};
